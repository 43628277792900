import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getUrlValue, getFormattedUrlObject, getUrlString, copyLabel} from "../../../utils/CommonUtils";
import TableLoader from "@components/Loaders/tableLoader";
import {Table, Input} from "antd";
import {TransactionType, TransactionStatus} from "@constants/Enums";
import moment from "moment-timezone";
import TransactionsFilter from "@components/TransactionsFilter";
import classNames from "classnames";
import {setDoneMsg, setErrorMsg} from "@actions/alertAction";
import {setPagination} from "@actions/paginationsActions";

import {getTransactions} from "@actions/transactionsActions";

import filterIcon from "@assets/img/icon/filter.svg";
import "./style.sass";




function Transactions({intl,pagination, loading, transactions, getTransactions, setPagination, setDoneMsg, setErrorMsg, clients}) {

    let history = useHistory()
    const search = history && history.location && history.location.search
    const [showFilter, setShowFilter] = useState(false);

    useEffect(() => {
        setPagination({
            page: Math.max(1, (+getUrlValue(search).page || 1)),
            limit: Math.max(10, (+getUrlValue(search).limit || 10)),
        })
        fetchTransactions(true)

        return () => {
            setPagination({
                page:  1,
                limit: 10
            })
        }
    }, [])

    const columns = [
        {
            title: intl.formatMessage({id: "id"}),
            dataIndex: 'id',
            width: "fit-content",
            render: (text, record) => {
                return <span className="copyable" onDoubleClick={(e) => copyLabel(e, record.id, setDoneMsg, setErrorMsg)} >{record.id}</span>
            }
        },
        {
            title: intl.formatMessage({id: "client_name"}),
            dataIndex: 'clientId',
            width: "fit-content",
            render: (clientId) => (<div>{clients.find(client => client.id === clientId)?.name}</div>)
        },
        {
            title: intl.formatMessage({id: "date"}),
            dataIndex: 'date',
            width: "fit-content",
            render: (text, record) => {
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                return <span>{moment(record.createDate).tz(timeZone).format("DD/MM/YYYY  HH:mm:ss")}</span>
            }

        },
        {
            title: intl.formatMessage({id: "type"}),
            dataIndex: 'type',
            width: "fit-content",
            render: (text, record) => {
                return <span><FormattedMessage id={TransactionType[record.type]}/></span>
            }

        },
        // {
        //     title: intl.formatMessage({id: "status"}),
        //     dataIndex: 'status',
        //     width: "fit-content",
        //     render: (text, record) => {
        //         return <span className={TransactionStatus[record.status]}><FormattedMessage
        //             id={TransactionStatus[record.status]}/></span>
        //     }

        // },
        {
            title: intl.formatMessage({id: "player_name"}),
            dataIndex: 'playerName',
            width: "fit-content"
        },
        {
            title: intl.formatMessage({id: "player_id"}),
            dataIndex: 'playerId',
            width: "fit-content",
            render: (playerId, record) => {
                return <span className="copyable" onDoubleClick={(e) => copyLabel(e, playerId, setDoneMsg, setErrorMsg)} >{playerId}</span>
            }
        },
        {
            title: intl.formatMessage({id: "amount"}),
            dataIndex: 'amount',
            width: "fit-content"
        },
        {
            title: intl.formatMessage({id: "product"}),
            dataIndex: 'productKey',
            width: "fit-content"
        }
    ];

    const getTransactionsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc || search);
        let initialForm = getFormattedUrlObject(urlObj)
        return {
            ...initialForm,
            statuses: initialForm.statuses ? initialForm.statuses.toString() : "",
            types: initialForm.types ? initialForm.types.toString() : "",
            ProductKeys: initialForm.ProductKeys ? initialForm.ProductKeys.toString() : "",
            CreateDateRange:{
                start: initialForm["CreateDateRange"] ? moment(initialForm["CreateDateRange"]["start"]).utc().format() : "",
                end: initialForm["CreateDateRange"] ? moment(initialForm["CreateDateRange"]["end"]).utc().format() : ""
            },
        }
    }


    const fetchTransactions = (isFirst) => {
        let initialForm = getTransactionsRequestBody()
        initialForm = {
            ...initialForm,
            page : Math.max(1, (+initialForm.page || (isFirst? 1 : pagination.page))),
            limit: Math.max(10, (+initialForm.limit || (isFirst? 10 : pagination.limit)))
        }
        setPagination({
            page: initialForm.page,
            limit: initialForm.limit,
        })
        if(Object.keys(initialForm).length) {
            getTransactions(initialForm)
            const {params} = getUrlString(initialForm)
            isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()})
        }
    }

    useEffect(() => {
        return history.listen((location) => {
            const loc = location.search
            if (loc) {
                const initialForm = getTransactionsRequestBody(loc)
                setPagination({
                    page: Math.max(1, (+initialForm.page || pagination.page)),
                    limit: Math.max(10, (+initialForm.limit || pagination.limit)),
                })
                // if (!user?.profile?.IsAdmin) {
                //     initialForm.clientId = user?.profile.sub;
                // }
                getTransactions(initialForm)
            }
        })
    },[history])


    const handleTablePaginate = (changedPage, changedLimit) => {
        let newPage = 1;

        if (pagination.limit === changedLimit) newPage = changedPage;
        setPagination({
            page: newPage,
            limit: changedLimit,
        })
        const initialForm = getTransactionsRequestBody()

        const {params} = getUrlString({...initialForm, page: newPage, limit: changedLimit})
        history.push({search: params.toString()})

    }

    return (
        <div className="content transactions">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='transactions'/></div>
                <button className={classNames("filterBtn", {show: showFilter})} onClick={() => setShowFilter(prev => !prev)}>
                    <div className="icon"/>
                    <FormattedMessage id='filter'/>
                </button>
            </div>
            <TransactionsFilter showFilter={showFilter} />
            <div className="transactions">
                <div className="mainTable">
                    {loading._transactions === undefined || loading._transactions ? <TableLoader count={pagination.limit} column={8} icon={false} drag={false} actions={0}/> :
                        <div>
                            <Table dataSource={transactions}
                                   columns={columns}
                                   rowKey="id"
                                   scroll={{x: '100%'}}
                                   pagination = {{
                                       total: pagination.total,
                                       showSizeChanger: true,
                                       showLessItems: true,
                                       onChange: (page, limit) => handleTablePaginate(page, limit),
                                       showTotal: (total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`,
                                       defaultPageSize: pagination.limit,
                                       defaultCurrent: pagination.page
                                   }}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        transactions: state.transactions,
        loading: state.loading,
        pagination: state.pagination,
        clients: state.clients
    }
}

const mapDispatchToProps = {
    getTransactions,
    setErrorMsg,
    setDoneMsg,
    setPagination
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Transactions));