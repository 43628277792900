import React from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import { Button, Space, Modal } from 'antd';
import LogoutIcon from "@components/logoutIcon";
import {EditOutlined} from "@ant-design/icons";
import {
    logoutUser
} from "@actions/userActions";
import UserEmptyImg from "@assets/img/userEmptyImg.svg";
import classNames from "classnames";

const { confirm } = Modal;

function UserLogoutPopup({logoutUser, user, className, intl}) {

    function showLogoutConfirm() {

        confirm({
            title: <span>
                <LogoutIcon />
            </span>,
            icon: false,
            content: intl.formatMessage({id: 'are_you_sure_you_want_to_sign_out'}),
            okText: intl.formatMessage({id: `sign_out`}),
            cancelText: intl.formatMessage({id: `cancel`}),
            okType: 'primary',
            className: 'sinout-popup',
            width: 440,
            onOk() {
                logoutUser();
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    }

    return (
        <Space direction="vertical" className={classNames( className, "ant-dropdown-menu ant-dropdown-menu-vertical drop-down")} size={[0, 16]} >
            <div className="user-info">
                <img src={user?.profile?.imageUrl || UserEmptyImg} />
                <div className="detals">
                    <h4>{user?.profile?.FirstName} {user?.profile?.LastName}</h4>
                    <h5>{user?.profile?.email}</h5>
                </div>
            </div>
            {/* <Button icon={<EditOutlined />} onClick={() => window.location =`${process.env.CORE_URL}edit-profile`}>
                <FormattedMessage id="edit_profile" />
            </Button> */}
            <Button icon={<LogoutIcon />} onClick={showLogoutConfirm}>
                <FormattedMessage id="sign_out" />
            </Button>
        </Space>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

const mapDispatchToProps = {
    logoutUser
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserLogoutPopup));