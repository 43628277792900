import React, { useEffect, useState } from "react";
import {injectIntl} from "react-intl";
import {Input} from "antd";
import classNames from "classnames";

function DepositInput({value, intl, resetDetect, onChange, ...props}) {

    const [val, setVal] = useState();
    const [errorMassege, setErrorMassege] = useState('')

    const changeDeposiBonus = (value) => {
        setVal(value.target.value);
        if (!isNaN(value.target.value) || !value.target.value) {
            onChange(value.target.value);
            setErrorMassege('')
        } else {
            setErrorMassege(intl.formatMessage({id: 'it_should_be_a_number'}));
        }
    }

    useEffect(() => {
        setVal(value);
    }, [value, resetDetect])

    return (
        <div className={classNames("deposit-input", {error: errorMassege})} {...props}>
            <Input value={val} placeholder={intl.formatMessage({id: `type_bonus`})} onChange={(value) => changeDeposiBonus(value)} title={errorMassege? errorMassege : value}/>
            {/* {errorMassege && <span  className="error-massege">{errorMassege}</span>} */}
        </div>
    )
}

export default injectIntl(DepositInput);