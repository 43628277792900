export const FETCH_FAILED = "FETCH_FAILED";

export const USER_LOGGING_REQUEST = "USER_LOGGING_REQUEST";
export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const SET_IS_END_SILENT = "SET_IS_END_SILENT";

export const SET_RESEND_FORGOT_PASSWORD = "SET_RESEND_FORGOT_PASSWORD";

// settings

// PUT
export const PUT_MIN_MAX_DURATIONS = "PUT_MIN_MAX_DURATIONS"

//SET
export const SET_MIN_MAX_DURATIONS = "SET_MIN_MAX_DURATIONS"

//GET
export const GET_MIN_MAX_DURATIONS = "GET_MIN_MAX_DURATIONS"

export const REQUEST_START_PATH = "REQUEST_START_PATH";
export const SET_START_PATH = "SET_START_PATH";


//errors
export const  SET_ERROR_MSG = "SET_ERROR_MSG"
export const  RESET_ERROR_MSG = "RESET_ERROR_MSG"
export const CLEAR_RESPONSE_ERROR = "CLEAR_RESPONSE_ERROR"
export const CHANGE_RESPONSE_ERROR = "CHANGE_RESPONSE_ERROR"

// players
export const GET_PLAYERS_BY_IP = "GET_PLAYERS_BY_IP"
export const SET_PLAYERS_BY_IP = "SET_PLAYERS_BY_IP"
export const RESET_PLAYERS_BY_IP = "RESET_PLAYERS_BY_IP"

//GET
export const GET_BET_LIST = "GET_BET_LIST"
export const GET_BET_LIST_FILTER = "GET_BET_LIST_FILTER"
export const GET_BET_CSV_DOWNLOADER_URL = "GET_BET_CSV_DOWNLOADER_URL"


//SET
export const SET_BET_LIST = "SET_BET_LIST"
export const SET_BET_LIST_FILTER = "SET_BET_LIST_FILTER"
export const SET_BET_CSV_DOWNLOADER_URL = "SET_BET_CSV_DOWNLOADER_URL"


//loading

export const CHANGE_LOADING = 'CHANGE_LOADING';

//pagination
export const UPDATE_PAGINATION = 'UPDATE_PAGINATION';
export const UPDATE_BY_START_PAGINATION = 'UPDATE_BY_START_PAGINATION';
export const UPDATE_PAGINATION_TOTAL = 'UPDATE_PAGINATION_TOTAL';


export const SET_LANG_LIST = "SET_LANG_LIST"

// coupons
export const SET_COUPONS = "SET_COUPONS";
export const REQUEST_COUPONS = "REQUEST_COUPONS";

export const REQUEST_ADD_COUPON = "REQUEST_ADD_COUPON";
export const SET_ADD_COUPON = "SET_ADD_COUPON";

export const REQUEST_UPDATE_DEPOSIT_ON = "REQUEST_UPDATE_DEPOSIT_ON";
export const SET_UPDATE_DEPOSIT_ON = "SET_UPDATE_DEPOSIT_ON";

export const REQUEST_ON_OFF_COUPON = "REQUEST_ON_OFF_COUPON";
export const SET_ON_OFF_COUPON = "SET_ON_OFF_COUPON";

export const REQUEST_SINGLE_COUPON = "REQUEST_SINGLE_COUPON";
export const SET_SINGLE_COUPON = "SET_SINGLE_COUPON";

export const REQUEST_EDIT_COUPON = "REQUEST_EDIT_COUPON";
export const SET_EDIT_COUPON = "SET_EDIT_COUPON";

export const CLEAR_COUPON = "CLEAR_COUPON";

export const SET_COUPON_SELECT = "SET_COUPON_SELECT";
export const REQUEST_COUPON_SELECT = "REQUEST_COUPON_SELECT";
export const CLEAR_COUPON_SELECT = "CLEAR_COUPON_SELECT";


// deposit groups
export const REQUEST_DEPOSIT_GROUPS = "REQUEST_DEPOSIT_GROUPS";
export const SET_DEPOSIT_GROUPS = "SET_DEPOSIT_GROUPS";

export const REQUEST_DEPOSIT_GROUP = "REQUEST_DEPOSIT_GROUP";
export const SET_DEPOSIT_GROUP = "SET_DEPOSIT_GROUP";

export const REQUEST_ADD_DEPOSIT_GROUP = "REQUEST_ADD_DEPOSIT_GROUP";
export const SET_ADD_DEPOSIT_GROUP= "SET_ADD_DEPOSIT_GROUP";

export const REQUEST_EDIT_DEPOSIT_GROUP = "REQUEST_EDIT_DEPOSIT_GROUP";
export const SET_EDIT_DEPOSIT_GROUP = "SET_EDIT_DEPOSIT_GROUP";

export const REQUEST_ON_OFF_GROUP = "REQUEST_ON_OFF_GROUP";
export const SET_ON_OFF_GROUP = "SET_ON_OFF_GROUP";

export const CLEAR_DEPOSIT_GROUPS = "CLEAR_DEPOSIT_GROUPS";


//coupon change history
export const REQUEST_COUPONS_HISTORY = "REQUEST_COUPONS_HISTORY";
export const SET_COUPONS_HISTORY = "SET_COUPONS_HISTORY";

export const RESET_COUPONS_HISTORY = "RESET_COUPONS_HISTORY";
export const UPDATE_HISTORY_PAGINATION_TOTAL = "UPDATE_HISTORY_PAGINATION_TOTAL";
export const UPDATE_HISTORY_PAGINATION = "UPDATE_HISTORY_PAGINATION";
export const REQUEST_COUPONS_BU_CLIENT_ID = "REQUEST_COUPONS_BU_CLIENT_ID";

// wallets
export const REQUEST_WALLETS = "REQUEST_WALLETS";
export const SET_WALLETS = "SET_WALLETS";
export const CLEAR_WALLETS = "CLEAR_WALLETS";
export const REQUEST_WALLETS_CSV_DOWNLOADER_URL = "REQUEST_WALLETS_CSV_DOWNLOADER_URL";
export const SET_WALLETS_CSV_DOWNLOADER_URL = "SET_WALLETS_CSV_DOWNLOADER_URL";
export const REQUEST_CANCEL_WALLETS_CSV_DOWNLOAD = "REQUEST_CANCEL_WALLETS_CSV_DOWNLOAD"
export const SET_CANCEL_WALLETS_CSV_DOWNLOAD = "SET_CANCEL_WALLETS_CSV_DOWNLOAD"

// clients
export const REQUEST_CLIENTS = "REQUEST_CLIENTS";
export const SET_CLIENTS = "SET_CLIENTS";
export const CLEAR_CLIENTS = "CLEAR_CLIENTS";
export const REQUEST_ADD_CLIENT = "REQUEST_ADD_CLIENT";
export const SET_ADD_CLIENT = "SET_ADD_CLIENT";
export const REQUEST_EDIT_CLIENT = "REQUEST_EDIT_CLIENT";
export const SET_EDIT_CLIENT = "SET_EDIT_CLIENT";

//products
export const SET_PRODUCTS = "SET_PRODUCTS"
export const REQUEST_PRODUCTS = "REQUEST_PRODUCTS"
export const CLEAR_PRODUCTS = "CLEAR_PRODUCTS"

//deposits
export const REQUEST_DEPOSITS = "REQUEST_DEPOSITS";
export const SET_DEPOSITS = "SET_DEPOSITS";
export const CLEAR_DEPOSITS = "CLEAR_DEPOSITS";
export const REQUEST_DEPOSITS_CSV_DOWNLOADER_URL = "REQUEST_DEPOSITS_CSV_DOWNLOADER_URL";
export const SET_DEPOSITS_CSV_DOWNLOADER_URL = "SET_DEPOSITS_CSV_DOWNLOADER_URL";
export const REQUEST_CANCEL_DEPOSIT_CSV_DOWNLOAD = "REQUEST_CANCEL_DEPOSIT_CSV_DOWNLOAD"
export const SET_CANCEL_DEPOSIT_CSV_DOWNLOAD = "SET_CANCEL_DEPOSIT_CSV_DOWNLOAD"

//transactions
export const REQUEST_TRANSACTIONS = "REQUEST_TRANSACTIONS";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL = "REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL";
export const SET_TRANSACTIONS_CSV_DOWNLOADER_URL = "SET_TRANSACTIONS_CSV_DOWNLOADER_URL";
export const REQUEST_CANCEL_TRANSACTIONS_CSV_DOWNLOAD = "REQUEST_CANCEL_TRANSACTIONS_CSV_DOWNLOAD";
export const SET_CANCEL_TRANSACTIONS_CSV_DOWNLOAD = "SET_CANCEL_TRANSACTIONS_CSV_DOWNLOAD";

// csv
export const SET_ALLOW_CSV_CANCEL = "SET_ALLOW_CSV_CANCEL";

//user

export const  REQUEST_USERS = "REQUEST_USERS";
export const  SET_USERS = "SET_USERS";