import initialStore from "../initialStore";
import {
    REQUEST_COUPONS,
    SET_COUPONS,
    SET_ADD_COUPON,
    SET_UPDATE_DEPOSIT_ON,
    SET_SINGLE_COUPON,
    SET_EDIT_COUPON,
    SET_ON_OFF_COUPON,
    CLEAR_COUPON,
    SET_COUPON_SELECT,
    REQUEST_COUPON_SELECT
} from "@actions/actionTypes";

export function couponsReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case CLEAR_COUPON:
            return initialStore.emptyArr;
        case SET_COUPONS:
            return  state.concat(action.coupons);
        case SET_ADD_COUPON:
            return [action.data, ...state];
        case SET_UPDATE_DEPOSIT_ON:
            return state.map(coupon => {
                if (coupon.id === action.coupon.id) {
                    return action.coupon;
                }
                return coupon;
            });
        case SET_EDIT_COUPON:
        case SET_ON_OFF_COUPON:
            return state.map(coupon => {
                if (coupon.id === action.data.id) {
                    return action.data;
                }
                return coupon;
            })
        default:
            return state;
    }
}

export function couponReducer(state = initialStore.emeptyObj, action) {
    switch (action.type) {
        case SET_SINGLE_COUPON:
            return  action.data;
        case CLEAR_COUPON:
            return initialStore.emptyArr;
        default:
            return state;
    }
}

export function couponSelectReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_COUPON_SELECT:
            return  action.coupons;
        case CLEAR_COUPON:
            return initialStore.emptyArr;
        default:
            return state;
    }
}