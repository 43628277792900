import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {Form, Input, Button, Select, Row, Col} from 'antd';
import {putMinMaxDurations, getMinMaxDurations} from "@actions/settingsActions";
// import {HmsToseconds, secondsToHms} from "@services/TradingRatesService";

function Settings({intl, putMinMaxDurations, minMaxDurations, getMinMaxDurations}) {
    const [form] = Form.useForm();
    const maxTimeLimitRef = useRef();
    const minTimeLimitRef = useRef();

    useEffect(() => {
        getMinMaxDurations()
    }, [])

    useEffect(() => {
        initialFields()
    }, [minMaxDurations])


    const initialFields = () => {
        // const {minTimeLimit = "", maxTimeLimit = ""} = minMaxDurations;
        // form.resetFields()
        // form.setFieldsValue({
        //     minTimeLimit: secondsToHms(minTimeLimit).date || "",
        //     maxTimeLimit: secondsToHms(maxTimeLimit).date || "",
        //     maxTimeLimitSelector: secondsToHms(maxTimeLimit).charset || "h",
        //     minTimeLimitSelector: secondsToHms(minTimeLimit).charset || "s"
        // })
    }

    // const onFinish = (values) => {
    //     const body = {
    //         minTimeLimit: HmsToseconds(values.minTimeLimit + values.minTimeLimitSelector),
    //         maxTimeLimit: HmsToseconds(values.maxTimeLimit + values.maxTimeLimitSelector)
    //     }
    //     putMinMaxDurations(body)
    // };
    return (
        <div className="content">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='settings'/></div>
            </div>
            <div className="settings">
                <Form form={form} name="horizontal_Time" layout="inline" onFinish={onFinish}>
                    <Row gutter={24} className="settings--row">
                        <Col xl={4} md={6} sm={8}>
                            <Form.Item
                                name="minTimeLimit"
                                className="verticalLabel"
                                dependencies={["maxTimeLimit"]}
                                rules={[
                                    {required: true, message: intl.formatMessage({id: `min_betting_time_is_required`})},
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (HmsToseconds(`${value}${getFieldValue("minTimeLimitSelector")}`) > HmsToseconds(`${getFieldValue("maxTimeLimit")}${getFieldValue("maxTimeLimitSelector")}`)) {
                                                return Promise.reject(intl.formatMessage({id: `min_betting_time_validation`}));
                                            }

                                            if (HmsToseconds(`${value}${getFieldValue("minTimeLimitSelector")}`) < 5) {
                                                return Promise.reject(intl.formatMessage({id: `min_betting_time_cant_be_less`}));
                                            }
                                            if (!(/^[0-9]+$/).test(value)) {
                                                return Promise.reject(intl.formatMessage({id: `min_betting_time_can_be_only_digits`}));
                                            }

                                            return Promise.resolve();

                                        },
                                    })
                                ]}
                                label={intl.formatMessage({id: "min_betting_time"})}
                            >
                                <Input
                                    ref={minTimeLimitRef}
                                    placeholder={intl.formatMessage({id: "type"})}
                                    addonAfter={
                                        <Form.Item noStyle name="minTimeLimitSelector">
                                            <Select className="select-after"
                                                    onChange={() => {
                                                        const value = form.getFieldsValue().minTimeLimit;
                                                        minTimeLimitRef.current.props.onChange(value)
                                                    }}>
                                                <Select.Option value="h"><FormattedMessage id='hour'/></Select.Option>
                                                <Select.Option value="m"><FormattedMessage id='minute'/></Select.Option>
                                                <Select.Option value="s"><FormattedMessage id='sec'/></Select.Option>
                                            </Select>
                                        </Form.Item>

                                    }/>
                            </Form.Item>
                        </Col>
                        <Col xl={4} md={6} sm={8} >
                            <Form.Item
                                name="maxTimeLimit"
                                className="verticalLabel"
                                dependencies={["minTimeLimit"]}
                                rules={[
                                    {required: true, message: intl.formatMessage({id: `max_betting_time_is_required`})},
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (HmsToseconds(`${value}${getFieldValue("maxTimeLimitSelector")}`) < HmsToseconds(`${getFieldValue("minTimeLimit")}${getFieldValue("minTimeLimitSelector")}`)) {
                                                return Promise.reject(intl.formatMessage({id: `max_betting_time_validation`}));
                                            }

                                            if (!(/^[0-9]+$/).test(value)) {
                                                return Promise.reject(intl.formatMessage({id: `max_betting_time_can_be_only_digits`}));
                                            }

                                            return Promise.resolve();

                                        },
                                    })
                                ]}
                                label={intl.formatMessage({id: "max_betting_time"})}
                            >
                                <Input
                                    ref={maxTimeLimitRef}
                                    placeholder={intl.formatMessage({id: "type"})}
                                    addonAfter={
                                        <Form.Item noStyle name="maxTimeLimitSelector">
                                            <Select className="select-after"
                                                    onChange={() => {
                                                        const value = form.getFieldsValue().maxTimeLimit;
                                                        maxTimeLimitRef.current.props.onChange(value)
                                                    }}>
                                                <Select.Option value="h"><FormattedMessage id='hour'/></Select.Option>
                                                <Select.Option value="m"><FormattedMessage id='minute'/></Select.Option>
                                                <Select.Option value="s"><FormattedMessage id='sec'/></Select.Option>
                                            </Select>
                                        </Form.Item>

                                    }/>
                            </Form.Item>
                        </Col>
                        <Col xl={16} md={12} sm={8}>
                            <Form.Item shouldUpdate>
                                {() => (
                                    <div className="btnGroup">
                                        <button type={"button"} onClick={initialFields} className="miniBtn default">
                                            <FormattedMessage id={"cancel"}/></button>
                                        <button className="miniBtn"
                                                disabled={form.getFieldError("maxTimeLimit").length || form.getFieldError("minTimeLimit").length}>
                                            <FormattedMessage id={"save"}/>
                                        </button>
                                    </div>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>


                </Form>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        minMaxDurations: state.minMaxDurations
    }
}

const mapDispatchToProps = {
    putMinMaxDurations,
    getMinMaxDurations
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Settings));