import React from "react";
import { Table, Progress } from "antd";
import {connect} from "react-redux";
import {injectIntl} from 'react-intl';
import {WalletsStatusType, WalletStatusTypeArr} from "@constants/Enums";
import {
    getDiffByDayMinuteSecond,
    getTimeLables
} from "../../utils/CommonUtils";
import moment from "moment-timezone";

function WalletsTable({intl, wallets, pagination, clients, changePagination}) {

    const getExpiredDate = (date) => {
        let diff = getTimeLables(getDiffByDayMinuteSecond(new Date(date).getTime() - new Date().getTime()), intl);
        return diff;
    }

    const progressStatus = ['exception', 'active', 'success'] //'expired', 'active', 'waggered'

    const columns = [
        {
            title: intl.formatMessage({id: "client"}),
            dataIndex: 'clientId',
            render: (text, record) => {
                return <div>{clients.find(client => client.id === record.clientId)?.name}</div>
            }
        },
        {
            title: intl.formatMessage({id: "player"}),
            dataIndex: 'playerName',
            render: (text, record) => {
                return <div>{text}</div>
            }
        },
        {
            title: intl.formatMessage({id: "amount"}),
            dataIndex: 'amount',
            render: (text, record) => {
                return <div>{text}</div>
            }

        },
        {
            title: intl.formatMessage({id: "wagering"}),
            dataIndex: 'remaining_wagering',
            width: 310,
            render: (text, record) => {
                return <Progress
                    strokeColor={{
                        from: 'rgba(0, 206, 126, 0.6)',
                        to: 'rgba(0, 103, 63, 1)',
                    }}
                    className="wagering-progress"
                    strokeWidth={14}
                    percent={((record.wagering - record.remainingWagering) / record.wagering) * 100}
                    format={() => (<span className="custom-progress-lable">{`${Number((record.wagering - record.remainingWagering).toFixed(5))}/${Number(record.wagering.toFixed(5))}`}</span>)}
                    status={progressStatus[record.status]}
                />
            }
        },
        {
            title: intl.formatMessage({id: "status"}),
            dataIndex: 'status',
            render: (text, record) => {
                return <div className={WalletStatusTypeArr[record.status] + ' text-capitalize'}>{WalletStatusTypeArr[record.status]}</div>
            }
        },
        {
            title: intl.formatMessage({id: "deposit_number_short"}),
            dataIndex: 'depositNumber',
            render: (text, record) => {
                return <div>{record.depositNumber}</div>
            }
        },
        {
            title: intl.formatMessage({id: "deposit_amount"}),
            dataIndex: 'depositAmount',
            render: (text, record) => {
                return <div>{record.depositAmount}</div>
            }
        },
        {
            title: intl.formatMessage({id: "deposit_date"}),
            dataIndex: 'depositDate',
            render: (text, record) => {
                return <div>{moment(record.depositDate).format('HH:mm:ss DD.MM.YY')}</div>
            }
        },
        {
            title: intl.formatMessage({id: "coupon_name"}),
            dataIndex: 'depositCouponName',
            render: (text, record) => {
                return <div>{record.depositCouponName}</div>
            }
        },
        {
            title: intl.formatMessage({id: "group"}),
            dataIndex: 'depositGroupName',
            render: (text, record) => {
                return <div>{record.depositGroupName}</div>
            }
        },
        {
            title: intl.formatMessage({id: "given"}),
            dataIndex: 'given',
            render: (text, record) => {
                return <div>{moment(record.given).format('HH:mm:ss DD.MM.YYYY') }</div>
            }
        },
        {
            title: intl.formatMessage({id: "expires_in"}),
            dataIndex: 'expireDate',
            render: (text, record) => {
                return <div>{moment(record.expireDate).format("HH:mm:ss DD.MM.YY")}</div>
            }
        },
    ];

    return (
        <Table dataSource={wallets}
            columns={columns}
            rowKey="id"
            pagination={
                {
                    showSizeChanger: true,
                    defaultCurrent: pagination.page,
                    defaultPageSize: pagination.limit,
                    showLessItems: true,
                    onChange: changePagination,
                    total: pagination.total,
                    showTotal: (total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({id: "of"})} ${total}`
                }
            }
        />
    )
}

function mapStateToProps(state) {
    return {
        wallets: state.wallets,
        pagination: state.pagination,
        clients: state.clients
    }
}

const mapDispatchToProps = {
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WalletsTable));
