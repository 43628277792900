import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_USERS,
    SET_USERS,
} from "@actions/actionTypes";
import {GetResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";
const API_URL = process.env.REACT_APP_API_IDP_PATH;

function* fetchUsers() {
    let users = yield call(GetResource, `/api/User`, API_URL);
    yield put({ type: SET_USERS, users: users.data});
}

function* usersSaga() {
    yield takeLatest(REQUEST_USERS,safe(onError, fetchUsers));
}

export default usersSaga;