import React, { useEffect } from "react";
import {FormattedMessage} from "react-intl";
import {PlusCircleTwoTone} from "@ant-design/icons";

export default function EmptyGroupsGrid({setShowModal}) {

    return (
        <div className="empty-groups-grid">
            <div>
                <span className="title">
                    <FormattedMessage id="for_to_start_please_create_the_first_deposit_group" />
                </span>
                <button className="mainBtn" onClick={() => setShowModal(true)}>
                    <PlusCircleTwoTone  className="pluse-icon"/>
                    <FormattedMessage id='create_group'/>
                </button>
            </div>
        </div>
    )
}