import {
    REQUEST_COUPONS,
    SET_COUPONS,
    REQUEST_UPDATE_DEPOSIT_ON,
    REQUEST_ON_OFF_COUPON,
    REQUEST_SINGLE_COUPON,
    REQUEST_EDIT_COUPON,
    REQUEST_ADD_COUPON,
    CLEAR_COUPON,
    SET_BUTTONS_DISABLED,
    REQUEST_COUPONS_BU_CLIENT_ID
} from "@actions/actionTypes";


export function getCoupons(id) {
    return dispatch => {
        dispatch({type: REQUEST_COUPONS, id});
    }
}

export function setCoupons(coupons) {
    return dispatch => {
        dispatch({type: SET_COUPONS, data: coupons});
    }
}

export function toggleCouponActive(coupon, groupId) {
    return dispatch => {
        dispatch({type: REQUEST_ON_OFF_COUPON, data: coupon, groupId});
    }
}

export function getSingleCoupon(id, groupId) {
    return dispatch => {
        dispatch({type: REQUEST_SINGLE_COUPON, data: {id, groupId}});
    }
}

export function saveDepositOnChange(depositsOn, groupId) {
    return (dispatch, getState) => {
        const {coupons} = getState();
        let newCoupons = [];
        coupons.forEach(coupon => {
            if (typeof depositsOn[coupon.id] != 'undefined') {
                newCoupons.push({
                    ...coupon,
                    on: depositsOn[coupon.id]
                });
            }
        })
        if (newCoupons.length) {
            dispatch({type: REQUEST_UPDATE_DEPOSIT_ON, coupons: newCoupons, groupId});
        }
    }
}


export function onSaveCoupon(data) {
    return dispatch => {
        dispatch({type: REQUEST_ADD_COUPON, data});
    }
}

export function onEditCoupon(coupon) {
    return dispatch => {
        dispatch({type: REQUEST_EDIT_COUPON, data: coupon});
    }
}

export function clearCoupons() {
    return dispatch => {
        dispatch({type: CLEAR_COUPON});
    }
}

export function getCouponsByClientID(id) {
    return dispatch => {
        dispatch({type: REQUEST_COUPONS_BU_CLIENT_ID, id});
    }
}