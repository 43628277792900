import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
    REQUEST_COUPONS,
    SET_COUPONS,
    REQUEST_UPDATE_DEPOSIT_ON,
    SET_UPDATE_DEPOSIT_ON,
    REQUEST_ON_OFF_COUPON,
    SET_ON_OFF_COUPON,
    REQUEST_SINGLE_COUPON,
    SET_SINGLE_COUPON,
    REQUEST_ADD_COUPON,
    SET_ADD_COUPON,
    UPDATE_PAGINATION,
    SET_EDIT_COUPON,
    REQUEST_EDIT_COUPON,
    UPDATE_BY_START_PAGINATION,
    UPDATE_PAGINATION_TOTAL,
    REQUEST_COUPONS_BU_CLIENT_ID,
    SET_ERROR_MSG
} from "@actions/actionTypes";
import {
    GetResource,
    DeleteResource,
    PutResource,
    PostResource
} from '../../utils/HttpUtil';
import {onError, safe} from "./handlerError";
import initialStore from "../initialStore";


let pagination = initialStore.pagination;
let oldFilter = {};

const getCorectFilter = (filter) => {
    if (filter && Object.keys(filter).length > 0) {
        oldFilter = filter;
    }
    return oldFilter;
}

let lang = 1;

function* setPagination(action) {
    pagination = action.pagination;
}

function* resetPagination(action) {
    pagination = action.pagination.Limit && action.pagination.Page && action.pagination || initialStore.pagination;
}

function* fetchCoupons({id}) {
    const coupons = yield call(GetResource, `/DepositGroup/${id}/coupon?page=${pagination.page}&limit=${pagination.limit}`);
    yield put({type: UPDATE_PAGINATION_TOTAL, total: coupons.totalCount});
    yield put({ type: SET_COUPONS, coupons: coupons.data });
}

function* fetchCouponsByClientId({id}) {
    const coupons = yield call(GetResource, `/DepositGroup/${id}/coupon?clientId=${id}`);
    yield put({ type: SET_COUPONS, coupons: coupons.data });
}


function* fetchCoupon(action) {
    const coupons = yield call(GetResource, `/DepositGroup/${action.data.groupId}/coupon/?id=${action.data.id}`);
    yield put({ type: SET_SINGLE_COUPON, data: coupons.data[0]});
}

function* addCoupon({data}) {
    const newCoupon = yield call(PostResource, `/DepositGroup/${data.groupId}/coupon`, {...data, status: 1});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
    yield put({ type: SET_ADD_COUPON, data: newCoupon.data });
}

function* editCoupon(coupon, groupId) {
    const newCoupon = yield call(PutResource, `/DepositGroup/${groupId}/coupon/${coupon.id}`, coupon);
    yield put({ type: SET_UPDATE_DEPOSIT_ON, coupon: newCoupon.data });
}

function* editSingleCoupon({data}) {
    const newCoupon = yield call(PutResource, `/DepositGroup/${data.groupId}/coupon/${data.id}`, data);
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
    yield put({ type: SET_EDIT_COUPON, data: newCoupon.data });
}

function* editCoupons({coupons, groupId}) {
    yield all(coupons.map(coupon => {
        return call(editCoupon, coupon, groupId);
    })) ;
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
}

function* toggleCouponStatus(action) {
    const coupon = action.data;
    const newCoupon = yield call(PutResource, `/DepositGroup/${action.groupId}/coupon/${coupon.id}`, {...coupon, status: 1 - (coupon.status? 1 : 0)});
    yield put({ type: SET_ON_OFF_COUPON, data: newCoupon.data });
}

function* couponsSaga() {
    yield takeLatest(REQUEST_COUPONS, safe(onError, fetchCoupons));
    yield takeLatest(REQUEST_COUPONS_BU_CLIENT_ID, safe(onError, fetchCouponsByClientId));
    yield takeLatest(REQUEST_UPDATE_DEPOSIT_ON, safe(onError, editCoupons));
    yield takeLatest(REQUEST_ON_OFF_COUPON, safe(onError, toggleCouponStatus));
    yield takeLatest(REQUEST_SINGLE_COUPON, safe(onError, fetchCoupon));
    yield takeLatest(REQUEST_ADD_COUPON, safe(onError, addCoupon));
    yield takeLatest(REQUEST_EDIT_COUPON, safe(onError, editSingleCoupon));
    yield takeLatest(UPDATE_PAGINATION, safe(onError, setPagination));
    yield takeLatest(UPDATE_BY_START_PAGINATION, safe(onError, resetPagination));
}
 
export default couponsSaga;