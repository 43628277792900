import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
    REQUEST_DEPOSIT_GROUPS,
    SET_DEPOSIT_GROUPS,
    REQUEST_ADD_DEPOSIT_GROUP,
    SET_ADD_DEPOSIT_GROUP,
    REQUEST_ON_OFF_GROUP,
    SET_ON_OFF_GROUP,
    SET_DEPOSIT_GROUP,
    REQUEST_DEPOSIT_GROUP,
    REQUEST_EDIT_DEPOSIT_GROUP,
    SET_EDIT_DEPOSIT_GROUP,
    UPDATE_PAGINATION,
    UPDATE_BY_START_PAGINATION,
    UPDATE_PAGINATION_TOTAL,
    SET_ERROR_MSG
} from "@actions/actionTypes";
import {
    GetResource,
    DeleteResource,
    PutResource,
    PostResource
} from '../../utils/HttpUtil';
import {onError, safe} from "./handlerError";
import initialStore from "../initialStore";
import {cache, loadImages} from "../../utils/SuspenseImage";

let pagination = initialStore.pagination;
let oldFilter = {};

const getCorectFilter = (filter) => {
    if (filter && Object.keys(filter).length > 0) {
        oldFilter = filter;
    }
    return oldFilter;
}

let lang = 1;

function* setPagination(action) {
    pagination = action.pagination;
}

function* resetPagination(action) {
    pagination = action.pagination.Limit && action.pagination.Page && action.pagination || initialStore.pagination;
}


function* fetchDepositGroups({clientId}) {
    const groups = yield call(GetResource, `/DepositGroup`, null, {'X-Client': clientId});
    yield call(loadImages, groups);
    yield put({ type: SET_DEPOSIT_GROUPS, groups: groups });
}

function* fetchDepositGroup({id, clientId}) {
    const group = yield call(GetResource, `/DepositGroup?id=${id}`, null, {'X-Client': clientId});
    yield put({ type: SET_DEPOSIT_GROUP, group: group[0] });
}

function* addDepositGroups({group, clientId, isCurrentClient}) {
    const groups = yield call(PostResource, `/DepositGroup`, {...group, status: group.status? 1 : 0}, null, {'X-Client': clientId});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
    yield put({ type: SET_ADD_DEPOSIT_GROUP, groups: groups.data, isCurrentClient});
}

function* editDepositGroup({data, clientId}) {
    const newGroup = yield call(PutResource, `/DepositGroup/${data.id}`, {...data, status: 1 - (data.status? 1 : 0)}, null, {'X-Client': clientId});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
    yield put({ type: SET_ON_OFF_GROUP, data: newGroup.data});
}

function* editDepositGroupIcon({data, clientId}) {
    const newGroup = yield call(PutResource, `/DepositGroup/${data.id}`, {...data, status: data.status? 1 : 0}, null, {'X-Client': clientId});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
    yield put({ type: SET_EDIT_DEPOSIT_GROUP, data: newGroup.data});
}

function* depositGroupsSaga() {
    yield takeLatest(REQUEST_DEPOSIT_GROUPS, safe(onError, fetchDepositGroups));
    yield takeLatest(REQUEST_ADD_DEPOSIT_GROUP, safe(onError, addDepositGroups));
    yield takeLatest(REQUEST_ON_OFF_GROUP, safe(onError, editDepositGroup));
    yield takeLatest(REQUEST_DEPOSIT_GROUP, safe(onError, fetchDepositGroup));
    yield takeLatest(REQUEST_EDIT_DEPOSIT_GROUP, safe(onError, editDepositGroupIcon));
}
 
export default depositGroupsSaga;