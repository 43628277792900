import {
    REQUEST_COUPONS_HISTORY,
    RESET_COUPONS_HISTORY
} from "@actions/actionTypes";


export function getCouponHistory(couponId, groupId) {
    return dispatch => {
        dispatch({type: REQUEST_COUPONS_HISTORY, couponId, groupId});
    }
}

export function resetCouponHistory() {
    return dispatch => {
        dispatch({type: RESET_COUPONS_HISTORY});
    }
}