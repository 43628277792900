import {
    PUT_MIN_MAX_DURATIONS,
    GET_MIN_MAX_DURATIONS
} from "@actions/actionTypes";

export function putMinMaxDurations(body) {
    return dispatch => {
        dispatch({
            type: PUT_MIN_MAX_DURATIONS,
            body
        })
    }
}


export function getMinMaxDurations() {
    return dispatch => {
        dispatch({
            type: GET_MIN_MAX_DURATIONS
        })
    }
}