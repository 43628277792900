import React, {useState} from 'react'
import { useHistory } from 'react-router';
import { Layout, Dropdown, Button, Space } from 'antd';
import "./style.sass";

import {
    logoutUser
} from "@actions/userActions";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import UserEmptyImg from "@assets/img/userEmptyImg.svg";
import UserLogoutPopup from "./UserLogoutPopup";

function UserAccount({intl, user, logoutUser}) {
    const [userPopup, setUserPopup] = useState('');
    const history = useHistory();

    return (
        <div>
            {/* <div className="logout">
                <img src={logoutIcon} alt="icon" className="icon" />
            </div> */}

            <Dropdown overlay={UserLogoutPopup} placement="bottomRight" trigger="click">
                <div className="user-acount-button">
                    <p className="logout-text">
                        <span>
                            {user?.profile?.FirstName} {user?.profile?.LastName}
                        </span>
                    </p>
                    <img src={user?.imageUrl || UserEmptyImg} />
                </div>
            </Dropdown>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

const mapDispatchToProps = {
    logoutUser
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserAccount));