import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
    GET_PLAYERS_BY_IP,
    SET_PLAYERS_BY_IP
} from "@actions/actionTypes";
import {
    GetResource,
    DeleteResource,
    PutResource,
    PostResource
} from '../../utils/HttpUtil';
import {onError, safe} from "./handlerError";


function* fetchPlayerByIp({ip}) {
    const players = yield call(GetResource, `/betting/bets/players?ip=${ip}`);
    yield put({ type: SET_PLAYERS_BY_IP, players: players.items });
}

function* playersSaga() {
    yield takeLatest(GET_PLAYERS_BY_IP, safe(onError, fetchPlayerByIp));
}
 
export default playersSaga;