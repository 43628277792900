export const DefaultLanguage = 'EN';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];

export const DepositGroupStatus = {
    Inactive: 0,
    Active: 1
}

export const CouponGroups = {
    Daily: 1,
    Weekly: 2,
    monthly: 3,
    Yearly: 4,
    Forever: 5
}

export const CouponGroupsValueArr = Object.values(CouponGroups)
export const CouponGroupsNameArr = Object.keys(CouponGroups)

export const WalletsStatusType = {
    pending: 0,
    active: 1,
    wagered: 2,
    expired: 3,
    lost: 4
}

export const WalletStatusTypeArr = Object.keys(WalletsStatusType);

export const TransactionType = {
    1: "bet",
    2: "win",
    3: "lose",
    4: "refund",
    5: "deposit"
}
export const TransactionTypeArr = Object.keys(TransactionType);

export const TransactionStatus = {
    1: "success",
    2: "pending",
    3: "failed",
    4: "timeout"
}
export const TransactionStatusArr = Object.keys(TransactionStatus);

export const CsvProgresstype = {
    inprogress: 1,
    finished: 2,
    error: 3,
    cancelled: 4,
    emptyTable: 5
}