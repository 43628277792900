import React, { useEffect, useRef, useState } from "react";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {FormattedMessage} from "react-intl";

import CouponForm from "@components/CouponForm";
import CouponsTable from "@components/CouponsTable";
import GroupItem from "@components/GroupItem";
import {
    getGroup
} from "@actions/depositGroupsAction";
import HeaderButtons from "@components/CouponsTable/HeaderButtons";
import {
    saveDepositOnChange,
    onSaveCoupon,
    onEditCoupon,
    clearCoupons
} from "@actions/couponsAction";
import {
    getProducts
} from "@actions/productsAction";
import "./style.sass";

function DepositGroupsDetals({getGroup, saveDepositOnChange, onSaveCoupon, onEditCoupon, clearCoupons, getProducts}) {

    let { id, clientId } = useParams();
    const [isShowCouponForm, setIsShowCouponForm] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(true);
    const depositChange = useRef({});
    const [canceldetect, setCanceldetect] = useState(true);
    const [couponEditId, setCouponEditId] = useState();
    const [windowScroll, setWindowScroll] = useState(window.scrollY);

    useEffect(() => {
        getGroup(id, clientId);
        getProducts(clientId);
        return () => {
            clearCoupons();
        }
    }, [])

    const setCouponEdit = (id) => {
        if (id == '') {
            setWindowScroll(window.scrollY)
        }
        setCouponEditId(id);
    }

    const onSave = (data) => {
        function getData() {
            const coupon = {
                ...data,
                groupId: id,
                percentage: +data.percentage,
                wagering: +data.wagering
            }

            data.on && (coupon.on = +data.on) || delete coupon.on;
            data.maxAmount && (coupon.maxAmount = +data.maxAmount) || delete coupon.maxAmount;
            data.minBalance && (coupon.minBalance = +data.minBalance) || delete coupon.minBalance;
            return coupon;
        }
        setIsShowCouponForm(false);
        if (data.id) {
            onEditCoupon(getData());
            setCouponEditId('');
        } else {
            onSaveCoupon(getData());
        }
        window.scrollTo(window.scrollX, windowScroll);
        document.querySelector(".site-layout").scrollTo(0, 0)
    }

    const onChangeDepositOn = (val) => {
        setButtonsDisabled(!Object.keys(val).length);
        depositChange.current = val;
    }

    const saveChange = () => {
        saveDepositOnChange(depositChange.current, id);
        depositChange.current = {};
        setButtonsDisabled(true);
    }

    const cancelChange = () => {
        setCanceldetect(prev => prev = !prev);
    }

    return (
        <div className="group-detals-main">
            <div className="group-detals">
                <div>
                    <GroupItem setIsShowCouponForm={setIsShowCouponForm} isShowCouponForm={isShowCouponForm} clientId={clientId}/>
                    {isShowCouponForm && <CouponForm onCancel={() => {
                                                                    setIsShowCouponForm(false);
                                                                    setCouponEditId('');
                                                                    window.scrollTo(window.scrollX, windowScroll);
                                                                    document.querySelector(".site-layout")?.scrollTo(0, 0);
                                                                }
                                                            }
                                                    onChange={onSave}
                                                    editId={couponEditId}
                                                    groupId={id} />
                                        || ''}
                </div>
                <div>
                    <div className="titleArea">
                        <div className="titleArea--title"><FormattedMessage id='deposit_group_coupons'/></div>
                        {/* <HeaderButtons buttonsDisabled={buttonsDisabled} saveChange={saveChange} cancelChange={cancelChange}/> */}
                    </div>
                    <CouponsTable   onChange={onChangeDepositOn}
                                    canceldetect={canceldetect}
                                    setEditingCouponId={(id) => {
                                        setIsShowCouponForm(false);
                                        setCouponEdit(id);
                                        requestAnimationFrame(() => {
                                            setIsShowCouponForm(true);
                                        })
                                    }}/>
                </div>
            </div>
        </div>
    )
}
function mapStateToProps(state) {
    return {
    }
}

const mapDispatchToProps = {
    getGroup,
    saveDepositOnChange,
    onSaveCoupon,
    onEditCoupon,
    clearCoupons,
    getProducts
}
export default connect(mapStateToProps, mapDispatchToProps)(DepositGroupsDetals);