import moment from "moment";

export const getFormattedForForm = (data) => {
    const initialData = {}
    Object.keys(data).forEach(key => {
        if (key === "types" || key === "statuses" || key === "ProductKeys") {
            if(!Array.isArray(data[key])) {
                initialData[key] = data[key].split(",")
            } else {
                initialData[key] = data[key]
            }
        } else if( typeof  data[key] === "object") {
            if(key.indexOf("CreateDate") > -1) {
                (!initialData[key]) && (initialData[key] = []);
                initialData[`${key}`][0] =  moment(data[key].start)
                initialData[`${key}`][1] =  moment(data[key].end)
            } else {
                initialData[`${key}Start`] = data[key].start
                initialData[`${key}End`] = data[key].end
            }
        }
        else {
            initialData[key] = data[key]
        }
    })
    return initialData
}

export const getDateForRequest = (date, formatted =false) => {
    if (date && date[0] && date[1]) {
        return {
            start: formatted ? moment(date[0]).utc().format() : moment(date[0].toString()),
            end: formatted ? moment(date[1]).utc().format() : moment(date[1].toString())
        }
    }
    return {start: "", end: ""}
}
export const getFormattedStateData = (data) => {
    return {
        id: data.id ? data.id : "",
        clientId: data.clientId ? data.clientId : "",
        CreateDateRange: getDateForRequest(data.CreateDateRange),
        types: data.types ? data.types.toString() : "",
        // statuses: data.statuses ? data.statuses.toString() : "",
        PlayerName: data.PlayerName ? data.PlayerName : "",
        PlayerId: data.PlayerId ? data.PlayerId : "",
        amountRange: {start: data.amountRangeStart || "", end: data.amountRangeEnd || ""},
        ProductKeys: data.ProductKeys ? data.ProductKeys.toString() : "",
    }
}