import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import {Modal, Spin, List, Tooltip, Typography} from "antd";
import {FormattedMessage, injectIntl} from "react-intl";
import InfiniteScroll from 'react-infinite-scroller';
import {
    getCouponHistory
} from "@actions/couponHistoryAction";
import moment from "moment";
import { formatDistance, subDays } from 'date-fns'
import {
    formatedStringByMinute,
    getOrdinalSuffix
} from "../../utils/CommonUtils";
const {Text} = Typography;

function CouponChangeHistory({show = true, loading, intl, couponId, groupId, historyPagination, couponHistory, onClose, getCouponHistory}) {

    const [hasMore, setHasMore] = useState(true);

    const getExpiredDate = (date) => {
        let diff = formatedStringByMinute(date)//getTimeLables(getDiffByDayMinuteSecond(new Date(date).getTime() - new Date().getTime()), intl);
        return diff;
    }

    useEffect(() => {
        if (couponId) {
            getCouponHistory(couponId, groupId);
        }
    }, [couponId])

    useEffect(() => {
        if (historyPagination.total <= couponHistory.length) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    }, [historyPagination, couponHistory])

    const handleInfiniteOnLoad = () => {
        if (loading._coupons_history != undefined && !loading._coupons_history) {
            getCouponHistory(couponId, groupId);
        }
    }

    const getvaluesByTitle = (oldValue, newValue) => {
        return (<>
            <span>
                <Tooltip title={oldValue} color={"#fff"} placement="top">
                    <Text style={{ maxWidth: '100%' }} ellipsis={true}>
                        {oldValue}
                    </Text>
                </Tooltip>
            </span>
            {'>'}
            <span>
                <Tooltip title={newValue} color={"#fff"} placement="top">
                    <Text style={{ maxWidth: '100%' }} ellipsis={true}>
                        {newValue}
                    </Text>
                </Tooltip>
            </span>
        </>)
    }

    const getHistoryItem = (propertyName, oldValue, newValue) => {
        switch(propertyName) {
            case 'ExpiresIn':
                return getvaluesByTitle(getExpiredDate(oldValue), getExpiredDate(newValue));
            case 'On': 
                return (
                    <>
                        <span> {(oldValue + getOrdinalSuffix(oldValue))} </span>
                            {'>'}
                        <span> {(newValue + getOrdinalSuffix(newValue))} </span>
                    </>
                );
            case 'ProductId':
                return (<><span title={oldValue} style={{maxWidth: 100}}> {oldValue} </span> {'>'} <span title={newValue} style={{maxWidth: 100}}> {newValue} </span></>);
            case 'Percentage':
            case 'Wagering':
            case 'MaxAmount':
            case 'MinAmount':
            case 'MinBalance':
                return getvaluesByTitle(Number(Number(oldValue).toFixed(2)), Number(Number(newValue).toFixed(2)))
            default:
                return getvaluesByTitle(oldValue, newValue);
        }
    }

    return (
        <Modal
            title={<FormattedMessage id='coupon_history' />}
            centered
            visible={show && couponId}
            width='auto'
            className={`formModal history-modal`}
            onCancel={() => onClose()}
            footer={false}>
            <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={handleInfiniteOnLoad}
                hasMore={loading._coupons_history != undefined && !loading._coupons_history && hasMore}
                useWindow={false}
                >
                <List
                    dataSource={couponHistory}
                    renderItem={history => (
                    <li key={history.id} className="history-row">
                        <div className="history-name">
                            <span className="bold">{history.userName}</span>
                        </div>
                        <div className="history-body">
                            {history.event && history.event.map(item =>  (
                                <div key={item.PropertyName} className="change-item">
                                    <span>{item.PropertyName} </span>
                                    {getHistoryItem(item.PropertyName, item.OldValue, item.NewValue)}
                                </div>
                            )) || <div className="change-item">
                                    <span><FormattedMessage id='created' /></span>
                                </div>}

                        </div>
                        <div className="time-diff">
                            <Tooltip title={moment(history.createDate).format("HH:mm:ss DD.MM.YY")} color={"#fff"} placement="bottom">
                                {formatDistance(moment(history.createDate).toDate(), new Date(), { addSuffix: true })}
                            </Tooltip>
                        </div>
                    </li>
                 )} >
                     {loading._coupons_history && hasMore && (
                        <div className="loading-container">
                            <Spin />
                        </div>
                    )}
                 </List>
           </InfiniteScroll>
        </Modal>
    )
}
function mapStateToProps(state) {
    return {
        couponHistory: state.couponHistory,
        loading: state.loading,
        historyPagination: state.historyPagination
    }
}

const mapDispatchToProps = {
    getCouponHistory
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CouponChangeHistory));