import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {DatePicker, Form, Input, Select, Tooltip} from "antd";
import classNames from "classnames";
import moment from "moment-timezone";
import {getTransactionsCsvLink, cancelTransactionCsvDownload } from "@actions/transactionsActions";
import {TransactionStatus, TransactionType, TransactionTypeArr, TransactionStatusArr} from "@constants/Enums";
import {getFormattedForForm, getFormattedStateData} from "@services/transactionsServices";
import {getUrlString, getUrlValue, getFormattedUrlObject, getQuery, checkNumberValidation} from "../../utils/CommonUtils";
import CsvDownloader from "@components/CSVDownloader/CsvDownloader";
import {
    setPagination
} from "@actions/paginationsActions"
import {
    getClientsList,
    clearClients
} from "@actions/clientsAction";


function TransactionsFilter({intl , pagination, clientSelect, showFilter, loading, user, setPagination, getClientsList, clearClients, getTransactionsCsvLink, cancelTransactionCsvDownload, allowCancel}) {
    let history = useHistory()
    const [form] = Form.useForm();
    const [products, setProducts] = useState([]);
    const [isDisableProductKeySelect, setIsDisableProductKeySelect] = useState(true);

    const onChangeClient = (clientId) => {
        const selectedClient = clientSelect.find(client => client.id === clientId);
        setProducts(selectedClient?.products || []);
        setIsDisableProductKeySelect(!selectedClient);
    }

    useEffect(() => {
        onChangeClient(form.getFieldValue('clientId') || !(user.profile.IsAdmin || user.profile.IsSuperAdmin) && user.profile.sub);
    }, [clientSelect])

    useEffect(() => {
        const loc = history && history.location && history.location.search;
        const urlObj = getUrlValue(loc);
        if (urlObj.clientId != form.getFieldValue('clientId')) {
            form.resetFields(['ProductKeys']);
        }
    }, [products])

    useEffect(() => {
        getClientsList('', '', true);
        return () => {
            clearClients();
        }
    }, []);

    const getTransactionsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc );
        let initialForm = getFormattedUrlObject(urlObj)
        return {
            ...initialForm,
            statuses: initialForm.statuses ? initialForm.statuses.toString() : "",
            types: initialForm.types ? initialForm.types.toString() : "",
            ProductKeys: initialForm.ProductKeys ? initialForm.ProductKeys.toString() : "",
            CreateDateRange:{
                start: initialForm["CreateDateRange"] ? moment(initialForm["CreateDateRange"]["start"]).utc().format() : "",
                end: initialForm["CreateDateRange"] ? moment(initialForm["CreateDateRange"]["end"]).utc().format() : ""
            },
        }
    }

    useEffect(() => {
        return history.listen((location) => {
            const loc = history && history.location && history.location.search
            const urlObj = getUrlValue(loc);

            let initialForm = getFormattedUrlObject(urlObj)
            
            form.resetFields()
            form.setFieldsValue(getFormattedForForm(initialForm))
        })
    },[history])

    const finishFilter = (data) => {
        const queryObj = {...getFormattedStateData(data), page : 1, limit: pagination.limit}

        if (data.CreateDateRange && data.CreateDateRange.length) {
            queryObj["CreateDateRangeStart"] =  data.CreateDateRange[0].toString()
            queryObj["CreateDateRangeEnd"] =  data.CreateDateRange[1].toString()
        }
        delete queryObj["CreateDateRange"]

        const {params} = getUrlString(queryObj)

        setPagination({
            page: 1,
            limit: pagination.limit,
        })

        history.push({search: params.toString()})
    }

    const resetFilter = () => {
        let initialForm = {}
        const initialObj = {start: "", end: ""}

        initialForm = {
            clientId: !(user.profile.IsAdmin || user.profile.IsSuperAdmin) && user.profile.sub || "",
            // statuses: [],
            types: [],
            ProductKeys: [],
            CreateDateRange: initialObj,
            amountRangeStart: "",
            amountRangeEnd: "",
            PlayerId: "",
            PlayerName: "",
            id:"",
        }

        form.setFieldsValue(initialForm)
        if (user.profile.IsAdmin || user.profile.IsSuperAdmin) {
            setProducts([]);
            setIsDisableProductKeySelect(true);
        }
    }

    const exportCVS = () => {
        if (loading._transactions_csv_downloader_url === undefined || !loading._transactions_csv_downloader_url) {
            let data = form.getFieldsValue();
            let initialForm = getFormattedStateData(data);
            if (initialForm.CreateDateRange.start) {
                initialForm.CreateDateRange.start = initialForm.CreateDateRange.start.utc().format();
            }
            if (initialForm.CreateDateRange.end) {
                initialForm.CreateDateRange.end = initialForm.CreateDateRange.end.utc().format();
            }
            getTransactionsCsvLink(initialForm)
        }
    }

    return (
        <div className="searchBar csv">
            <Form
                form={form}
                onFinish={finishFilter}
                validateTrigger="onBlur"
                layout="vertical">
                <div className={classNames("searchBar--row",{show: showFilter})}>
                    <div className="searchBar--col">
                        <Form.Item
                            name="id"
                            label={<FormattedMessage id={"transaction_id"}/>} required={false}
                            rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        const ipRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)
                                        if ((value && value.length > 1) && !ipRegExp )
                                        {
                                            return Promise.reject(intl.formatMessage({id: `value_must_be_GUID`}))
                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_transaction_id"})}/>
                        </Form.Item>
                    </div>
                    <div className={classNames("searchBar--col", {hidden: !(user?.profile?.IsAdmin || user?.profile?.IsSuperAdmin)})}>
                        <Form.Item
                            label={<FormattedMessage id={"client"}/>} name="clientId">
                            <Select allowClear
                                    className="client-list-select"
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    onChange={onChangeClient}
                            >
                                {clientSelect.map(client => (
                                    <Select.Option key={client.id} value={client.id}>{client.name}</Select.Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            label={<FormattedMessage id={"product_key"}/>} name="ProductKeys">
                            <Select mode="multiple"
                                className="product-list-select"
                                disabled={isDisableProductKeySelect}
                                dropdownClassName='checkbox-mode'
                                placeholder={intl.formatMessage({id: "select_product_keys"})}
                                getPopupContainer={triggerNode => triggerNode.parentElement}
                                
                                optionLabelProp="label" showArrow showSearch={false}>
                                {products.map(product => (
                                    <Select.Option key={product.id}
                                                    value={product.key}
                                                    label={product.name} >
                                            {product.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"date"}/>}
                                   name="CreateDateRange">
                            <DatePicker.RangePicker  getPopupContainer={triggerNode => triggerNode.parentElement} showTime/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"type"}/>} name="types">
                            <Select mode="multiple" getPopupContainer={triggerNode => triggerNode.parentElement}
                                    dropdownClassName='checkbox-mode'
                                    optionLabelProp="label" showArrow showSearch={false}>
                                {TransactionTypeArr.map(type => (
                                    <Select.Option key={type} value={""+type}
                                                   label={
                                                    <span className={TransactionType[status]}>
                                                        <FormattedMessage id={TransactionType[type]}/>
                                                    </span>}>
                                        <span className={TransactionType[status]}>
                                            <FormattedMessage id={TransactionType[type]}/>
                                        </span>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    {/* <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"status"}/>} name="statuses">
                            <Select mode="multiple"
                                    dropdownClassName='checkbox-mode'
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    optionLabelProp="label" showArrow showSearch={false}>
                                {TransactionStatusArr.map(status => (
                                    <Select.Option key={status} value={""+status}
                                                   label={
                                                        <span className={TransactionStatus[status]}>
                                                            <FormattedMessage id={TransactionStatus[status]}/>
                                                       </span>}>
                                        <span className={TransactionStatus[status]}>
                                            <FormattedMessage id={TransactionStatus[status]}/>
                                        </span>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div> */}
                    <div className="searchBar--col">
                        <Form.Item
                            name="PlayerName"
                            label={<FormattedMessage id={"player_name"}/>} >
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_player_name"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            name="PlayerId"
                            label={<FormattedMessage id={"player_id"}/>} >
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_player_id"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item className={"numberRangeInput"} label={<FormattedMessage id={"amount"}/>}>
                            <Form.Item name="amountRangeStart" noStyle>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "from"})} onKeyDown={checkNumberValidation} />
                            </Form.Item>
                            <div className="separator"/>
                            <Form.Item name="amountRangeEnd" noStyle>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "to"})} onKeyDown={checkNumberValidation} />
                            </Form.Item>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col btnBox" style={{width: 329, maxWidth: 330}}>
                        <div className="filterPart">
                            <button type="button"
                                    className="miniBtn default small"
                                    style={{minWidth: 110}}
                                    onClick={resetFilter}>
                                <FormattedMessage id='clear'/>
                            </button>
                            <button type="submit"
                                    style={{minWidth: 110}}
                                    className="miniBtn small">
                                <FormattedMessage id='search'/>
                            </button>
                        </div>
                        <div className="exportPart" style={{minWidth: 100}}>
                            <CsvDownloader exportCVS={exportCVS}
                                           downloadLoading={loading._transactions_csv_downloader_url}
                                           cancelLoading={loading._cancel_transactions_csv_download}
                                           onCancel={cancelTransactionCsvDownload}
                                           allowCancel={allowCancel}/>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        pagination: state.pagination,
        clientSelect: state.clients,
        loading: state.loading,
        allowCancel: state.csvAllowCancel.allowTransaction,
        user: state.user
    }
}

const mapDispatchToProps = {
    setPagination,
    getClientsList,
    clearClients,
    getTransactionsCsvLink,
    cancelTransactionCsvDownload
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TransactionsFilter));