import React, {useState, useEffect} from "react";
import {Select, Skeleton} from "antd";
import {connect} from "react-redux";

function ClientsSelect({clients, loading, selectedClient = '', ...presProps}) {

    return (
        <>
            {selectedClient &&
                <Select className="client-list-select" defaultValue={selectedClient} {...presProps} getPopupContainer={triggerNode => triggerNode.parentElement}>
                    {clients.map(client => (
                        <Select.Option key={client.id}
                                        value={client.id}
                                        >{client.name}</Select.Option>
                    ))}
                </Select> || <Skeleton.Input style={{width: '100%'}} active={true}/>
            }
        </>
    )
}
function mapStateToProps(state) {
    return {
        clients: state.clients,
        loading: state.loading
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsSelect);