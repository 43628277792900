import initialStore from "../initialStore";
import {SET_ADD_DEPOSIT_GROUP, SET_DEPOSIT_GROUPS, CLEAR_DEPOSIT_GROUPS} from "@actions/actionTypes";

export default function depositGroupsReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_DEPOSIT_GROUPS:
            return  action.groups;
        case SET_ADD_DEPOSIT_GROUP:
            if (action.isCurrentClient) {
                return [action.groups].concat(state)
            }
            return state;
        case CLEAR_DEPOSIT_GROUPS:
            return initialStore.emptyArr;
        default:
            return state;
    }
}
