import initialStore from "../initialStore";
import {
    UPDATE_PAGINATION,
    UPDATE_BY_START_PAGINATION
} from "./actionTypes";

export function setPagination(pagination) {
    return dispatch => {
        dispatch({type: UPDATE_PAGINATION, pagination})
    }
}

export function resetPagination(pagination = initialStore.pagination) {
    return dispatch => {
        dispatch({type: UPDATE_BY_START_PAGINATION, pagination})
    }
}