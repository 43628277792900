import initialStore from "../initialStore";
import {
    REQUEST_COUPONS_HISTORY,
    SET_COUPONS_HISTORY,
    RESET_COUPONS_HISTORY,
    UPDATE_HISTORY_PAGINATION_TOTAL,
    UPDATE_HISTORY_PAGINATION
} from "@actions/actionTypes";

export function couponHistoryReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_COUPONS_HISTORY:
            return  state.concat(action.history);
        case RESET_COUPONS_HISTORY:
            return initialStore.emptyArr;
        default:
            return state;
    }
}

export function historyPaginationReducer(state = initialStore.pagination, action) {
    switch(action.type) {
        case UPDATE_HISTORY_PAGINATION_TOTAL:
            return {
                ...state,
                total: action.total
            }
        case UPDATE_HISTORY_PAGINATION:
            return {
                ...state,
                ...action.pagination
            }
        case RESET_COUPONS_HISTORY:
            return initialStore.pagination;
        default:
            return state;
    }
}