import {all, call} from 'redux-saga/effects';
import settingsSaga from "./settingsSaga";
import errorSaga from "./errorSaga";
import playersSaga from "./playersSaga";
import depositGroupsSaga from "./depositGroupsSaga";
import couponsSaga from "./couponsSaga";
import couponHistorySaga from "./couponHistorySaga";
import depositsSaga from "./depositSaga";
import clientsSaga from "./clientsSaga";
import walletsSaga from "./walletsSaga";
import productsSaga from "./productsSaga";
import transactionsSaga from "./transactionsSaga";
import usersSaga from "./coreUsersSaga";

export default function* rootSaga() {
    yield all([
        call(settingsSaga),
        call(errorSaga),
        call(playersSaga),
        call(depositGroupsSaga),
        call(couponsSaga),
        call(couponHistorySaga),
        call(depositsSaga),
        call(clientsSaga),
        call(walletsSaga),
        call(productsSaga),
        call(transactionsSaga),
        call(usersSaga)
    ])
}