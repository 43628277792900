import React, {useEffect, useContext, useState} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {Input, Table, Tooltip, Form, Typography} from "antd";
import {FormattedMessage, injectIntl} from "react-intl";
import {SearchOutlined} from "@ant-design/icons";
import moment from "moment-timezone";


import TableLoader from "@components/Loaders/tableLoader";
import {getFormattedUrlObject, getUrlString, getUrlValue, copyLabel} from "../../../utils/CommonUtils";
import ClientControlPopup from "@components/ClientControlPopup/ClientControlPopup";
import {
    getFormattedForForm,
    getFormattedStateData
} from "@services/clientsService";
import { REQUEST_ADD_CLIENT } from '@actions/actionTypes';
import {getClientsList, clearClients} from "@actions/clientsAction";
import {setDoneMsg, setErrorMsg} from "@actions/alertAction";
import {
    ResponseContext
} from "../../../hooks/ResponseObserver";
const {Text} = Typography;

import  './style.sass';

function Clients({loading, intl, getClientsList, clientsList, setDoneMsg, setErrorMsg, clearClients}) {

    let history = useHistory();
    const search = history && history.location && history.location.search
    const [form] = Form.useForm();
    const [clientControlPopup, setClientControlPopup] = useState('');
    const [tableData, setTableData] = useState([]);
    const [singleClient, setSingleClient] = useState({});
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);

    const initFormData = (loc) => {
        const urlObj = getUrlValue(loc);
        let initialForm = getFormattedUrlObject(urlObj);

        form.resetFields();
        form.setFieldsValue(getFormattedForForm(initialForm));
    }

    useEffect(() => {
        subscribeForError(REQUEST_ADD_CLIENT, () => {
            setClientControlPopup('');
        });
        return () => {
            unSubscribeForError(REQUEST_ADD_CLIENT);
        }
    }, [])

    useEffect(() => {
        const loc = history && history.location && history.location.search
        initFormData(loc)
    }, [])

    const finishFilter = (data) => {
        const {params} = getUrlString({
            ...getFormattedStateData(data)
        });
        history.push({search: params.toString()});
    }

    const resetFilter = () => {
        form.resetFields();
    }

    const columns = [
        {
            title: intl.formatMessage({id: "id"}),
            dataIndex: 'id',
            render: (id, record) => {
                return <span onDoubleClick={(e) => copyLabel(e, id, setDoneMsg, setErrorMsg)} >{id}</span>
            }
        },
        {
            title: intl.formatMessage({id: "name"}),
            dataIndex: 'name',
            render: (name) => {
                return <Text ellipsis={{rows: 1}}>{name}</Text>
            }
        },
        {
            title: intl.formatMessage({id: "created"}),
            dataIndex: 'createDate',
            render: (text, record) => {
                return moment(text).format('HH:mm:ss DD.MM.YY')
            }
        },
        {
            title: intl.formatMessage({id: "actions"}),
            dataIndex: 'actions',
            align: 'right',
            render: (text, record) => {
                return (<div className="iconBox">
                    <Tooltip title={<FormattedMessage id='edit'/>} color={"#fff"} placement="bottom">
                        <div className="edit-icon icon" onClick={() => {
                            setSingleClient(record);
                            setClientControlPopup("edit")
                        }}/>
                    </Tooltip>

                </div>);
            },
        },
    ];

    const getClientsRequestBody = (loc) => {
        const urlObj = getUrlValue(loc);
        let initialForm = getFormattedUrlObject(urlObj)
        return {
            ...initialForm,
            clientId: initialForm.clientId ? initialForm.clientId : "",
            clientName: initialForm.clientName ? initialForm.clientName : ""
        }
    }

    const fetchClients = (isFirst) => {
        const loc = history && history.location && history.location.search
        let initialForm = getClientsRequestBody(loc);
        setTableData(clientsList.filter(client => (client.name.toLocaleLowerCase().indexOf(initialForm.clientName.toLocaleLowerCase()) != -1 || !initialForm.clientName) && (client.id.indexOf(initialForm.clientId) != -1 || !initialForm.clientId)));
        const {params} = getUrlString(initialForm)
        isFirst ? history.replace({search: params.toString()}) : null
        
    }

    useEffect(() => {
        fetchClients(true);
    }, [clientsList])

    useEffect(() => {
        getClientsList();
        return () => {
            clearClients();
        }
    }, [])

    useEffect(() => {
        return history.listen((location, action) => {
            const loc = location.search;
            if (action == 'PUSH' || action == 'POP') {
                fetchClients();
            }
        });
    },[history, clientsList])

    return (
        <div className="content clients">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='clients'/></div>
                <div className="titleArea--BtnGroup">
                    <button className="mainBtn" onClick={() => setClientControlPopup("add")}>
                        <div className="icon"/>
                        <FormattedMessage id='add_client'/>
                    </button>
                </div>
            </div>
            <div className="searchBar ">
                <Form
                    form={form}
                    onFinish={finishFilter}
                    className="clients-filter"
                    layout="vertical">
                    <div className="searchBar--row">
                        <div className="searchBar--col">
                            <Form.Item
                                label={<FormattedMessage id={"type_id"}/>} name="clientId">
                                <Input suffix={<SearchOutlined/>}
                                    placeholder={intl.formatMessage({id: "type"})}
                                    autoComplete="off"
                                />
                            </Form.Item>
                        </div>
                        <div className="searchBar--col">
                            <Form.Item
                                label={<FormattedMessage id={"client_name"}/>} name="clientName">
                                <Input suffix={<SearchOutlined/>}
                                    placeholder={intl.formatMessage({id: "type_client_name"})}
                                    autoComplete="off"
                                />
                            </Form.Item>
                        </div>
                        <div className="searchBar--col btnBox">
                            <button type="button" className="miniBtn default small" onClick={resetFilter}>
                                <FormattedMessage id='clear'/>
                            </button>
                            <button type="submit" className="miniBtn small">
                                <FormattedMessage id='search'/>
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="mainTable clients">
                {
                    loading._clients === undefined || loading._clients ? <TableLoader count={13} column={2} actions={1} drag={false} icon={false}/> :
                        <Table
                            dataSource={tableData}
                            columns={columns}
                            rowKey="id"
                            scroll={{x: "100%"}}
                            pagination={false}
                            />
                }

            </div>
            {
                clientControlPopup? <ClientControlPopup handleCancel={() => {setClientControlPopup(""); setSingleClient({})}}
                                                        show={clientControlPopup}
                                                        client={singleClient}/> : ""
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        clientsList: state.clients,
        loading: state.loading,
        pagination: state.pagination
    }
}

const mapDispatchToProps = {
    getClientsList,
    clearClients,
    setDoneMsg,
    setErrorMsg
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Clients));