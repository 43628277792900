import initialStore from "../initialStore";
import {
    SET_CLIENTS,
    SET_EDIT_CLIENT,
    CLEAR_CLIENTS
} from "@actions/actionTypes";

export default function clientsReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_CLIENTS:
            return  action.clients
        case CLEAR_CLIENTS:
            return initialStore.emptyArr;
        case SET_EDIT_CLIENT:
            return state.map(item => {
                if (item.id === action.data.id) {
                    return action.data;
                }
                return item;
            });
        default:
            return state;
    }
}
