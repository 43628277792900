import React, { useEffect, useMemo, useRef, useState, useContext } from "react";
import {connect} from "react-redux";
import {Tooltip, Switch, Spin, Table, Empty} from "antd";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    getCoupons,
    toggleCouponActive,
    clearCoupons
} from "@actions/couponsAction";
import {
    // getDiffByDayMinuteSecond,
    // getTimeLables,
    getOrdinalSuffix,
    formatedStringByMinute
} from "../../utils/CommonUtils";
import DepositInput from "../DepositInput";
import CouponChangeHistory from '../CouponChangeHistory';
import {
    resetCouponHistory,
} from '@actions/couponHistoryAction';
import {
    setPagination
} from "@actions/paginationsActions";
import InfiniteScroll from 'react-infinite-scroller';
import { useParams } from "react-router-dom";
import classNames from "classnames";
import {
    REQUEST_UPDATE_DEPOSIT_ON
} from "@actions/actionTypes";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";

function CouponsTable({intl, coupons, selectedGroup, loading, canceldetect, onChange, getCoupons, toggleCouponActive, setEditingCouponId, pagination, resetCouponHistory, setPagination, clearCoupons, groupId}) {

    const changeDepositOn = useRef({});
    const [historyCouponId, setHistoryCouponId] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const tableRef = useRef();
    let { id } = useParams();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);


    useEffect(() => {
        clearCoupons();
        subscribeForError(REQUEST_UPDATE_DEPOSIT_ON, () => {
            changeDepositOn.current = {};
            onChange(changeDepositOn.current);
        });

        const debounce = (fn) => {
            let frame;
            return (...params) => {
                if (frame) { 
                    cancelAnimationFrame(frame);
                }
                frame = requestAnimationFrame(() => {
                    fn(...params);
                });
            } 
        };
        
        const storeScroll = () => {
            let pos = tableRef.current.scrollLeft
            document.documentElement.setAttribute('style', `--scrollpos: ${pos}px`);
        }
        tableRef.current.addEventListener('scroll', debounce(storeScroll), { passive: true });
        storeScroll();

        return () => {
            setPagination({page: 1, limit: pagination.limit});
            unSubscribeForError(REQUEST_UPDATE_DEPOSIT_ON);
        }
    }, [])

    useEffect(() => {
        setHasMore(!(coupons.length >= +pagination.total));
    }, [coupons, pagination.total])

    useEffect(() => {
        if (!historyCouponId) {
            resetCouponHistory();
        }
    }, [historyCouponId])

    useEffect(() => {
        if (id) {
            setPagination({page: 1, limit: Math.max(20, pagination.limit)});
            getCoupons(id);
        }
    }, [id])

    const getExpiredDate = (date) => {
        let diff = formatedStringByMinute(date)//getTimeLables(getDiffByDayMinuteSecond(new Date(date).getTime() - new Date().getTime()), intl);
        return diff;
    }

    const changeCouponDepositBonus = (val, coupon) => {
        val = val.toString().replace(/\ /g, '');
        if ((val || '') != (coupon.on || '')) {
            changeDepositOn.current[coupon.id] = val;
        } else {
            delete changeDepositOn.current[coupon.id];
        }
        onChange(changeDepositOn.current);
    }

    useEffect(() => {
        changeDepositOn.current = {};
        onChange(changeDepositOn.current);
    }, [canceldetect])
    
    const handleFetch = (p) => {
        if (!hasMore) return;
        setHasMore(false);
        setPagination({page: p + 1, limit: Math.max(20, pagination.limit)});
        if (selectedGroup.id) {
            getCoupons(selectedGroup.id);
        }
    }

    const toggleCoupon = (coupon, id) => {
        const {products, ...rest } = coupon
        const productsId = products ?  products.map(product => product.id) : [];

        toggleCouponActive({
            ...rest, productsId
        }, id)
    }

    return (
        <div className="mainTable coupons-table-block">
            <div className="ant-table-wrapper">
                <div className="ant-spin-nested-loading">
                    <div className="ant-spin-container">
                        <div className="ant-table ant-table-fixed-header">
                            <div className="ant-table-container">
                                <table style={{tableLayout: 'auto'}} ref={tableRef}>
                                    <thead className="ant-table-thead">
                                        <tr>
                                            <th style={{width: 100}} className="ant-table-cell"><FormattedMessage id='name'/></th>
                                            <th style={{width: 110}} className="ant-table-cell"><FormattedMessage id='percentage'/></th>
                                            <th style={{width: 110}} className="ant-table-cell"><FormattedMessage id='wagering'/></th>
                                            <th style={{width: 120}} className="ant-table-cell"><FormattedMessage id='expires_in'/></th>
                                            <th className="ant-table-cell"><FormattedMessage id='max_bonus_amount'/></th>
                                            <th style={{width: 260}} className="ant-table-cell"><FormattedMessage id='deposit_number_short'/></th>
                                            <th className="ant-table-cell"><FormattedMessage id='min_balance'/></th>
                                            <th className="ant-table-cell"><FormattedMessage id='actions'/></th>
                                        </tr>
                                    </thead>
                                    <InfiniteScroll
                                        initialLoad={false}
                                        element="tbody"
                                        className="ant-table-tbody"
                                        loadMore={handleFetch}
                                        hasMore={loading._coupons != undefined && !loading._coupons && hasMore}
                                        useWindow={false} >
                        
                                        {coupons.map(coupon => (
                                            <tr key={coupon.id} className="ant-table-row ant-table-row-level-0">
                                                <td className="ant-table-cell">{coupon.name}</td>
                                                <td className="ant-table-cell text-right">{coupon.percentage}%</td>
                                                <td className="ant-table-cell text-right">{coupon.wagering}x</td>
                                                <td className="ant-table-cell">{getExpiredDate(coupon.expiresIn)}</td>
                                                <td className="ant-table-cell text-right">{coupon.maxAmount || ''}</td>
                                                <td className="ant-table-cell">
                                                {coupon.on && coupon.on + getOrdinalSuffix(coupon.on) || <FormattedMessage id="always"/>}
                                                    {/* <DepositInput value={coupon.on}
                                                            key={coupon.id + 'input'}
                                                            onChange={(value) => changeCouponDepositBonus(value, coupon)}
                                                            resetDetect={canceldetect} /> */}
                                                </td>
                                                <td className="ant-table-cell text-right">{coupon.minBalance || ''}</td>
                                                <td className="ant-table-cell">
                                                    <div className="iconBox">
                                                        <Tooltip title={ <FormattedMessage id={`${coupon.status ? "disabled" : "enabled"}`}/>  } color={"#fff"} placement="bottom">
                                                            <Switch checked={coupon.status}
                                                                    className="radioSwitch"
                                                                    loading={loading['_on_off_coupon' + coupon.id]}
                                                                    onChange={() => {
                                                                        toggleCoupon(coupon, id);
                                                                    }}/>
                                                        </Tooltip>
                                                        <Tooltip title={<FormattedMessage id='edit'/>} color={"#fff"} placement="bottom">
                                                            <div className="edit-icon icon" onClick={() => {
                                                                setEditingCouponId(coupon.id)
                                                            }}/>
                                                        </Tooltip>
                                                        <Tooltip title={<FormattedMessage id='history'/>} color={"#fff"} placement="bottom">
                                                            <div className="history-icon icon" onClick={() => {
                                                                setHistoryCouponId(coupon.id);
                                                            }}/>
                                                        </Tooltip>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        {loading._coupons && (
                                            <tr key={'loading'} className={classNames("loading-container", {'is-first-load': (coupons.length == 0 && loading._coupons)})} >
                                                <th colSpan={8}>
                                                    <Spin />
                                                </th>
                                            </tr>
                                        )}
                                        {loading._coupons != undefined && !loading._coupons && coupons.length === 0 && (
                                            <tr>
                                                <td style={{height: 200}} colSpan={8}>
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                </td>
                                            </tr>
                                        )}
                                    </InfiniteScroll>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CouponChangeHistory couponId={historyCouponId} groupId={id} onClose={() => {setHistoryCouponId('')}} />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        coupons: state.coupons,
        loading: state.loading,
        selectedGroup: state.selectedGroup,
        pagination: state.pagination
    }
}

const mapDispatchToProps = {
    getCoupons,
    toggleCouponActive,
    resetCouponHistory,
    setPagination,
    clearCoupons
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CouponsTable));
