import {
    REQUEST_CLIENTS,
    REQUEST_ADD_CLIENT,
    REQUEST_EDIT_CLIENT,
    CLEAR_CLIENTS
} from "@actions/actionTypes";

export function getClientsList(nameLike="", sortDirDesc="", isSortByName) {
    return dispatch => {
        dispatch({
            type: REQUEST_CLIENTS,
            nameLike,
            sortDirDesc,
            isSortByName
        });
    }
}

export function addClient(body) {
    return dispatch => {
        dispatch({
            type: REQUEST_ADD_CLIENT,
           body,
           isOpenedByPopup: true
        });
    }
}

export function editClient(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_EDIT_CLIENT,
            data,
            isOpenedByPopup: true
        });
    }
}


export function clearClients() {
    return dispatch => {
        dispatch({
            type: CLEAR_CLIENTS
        });
    }
}