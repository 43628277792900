import {call, put, delay, take, fork, cancel} from 'redux-saga/effects';
import {
    REQUEST_WALLETS,
    SET_WALLETS,
    UPDATE_PAGINATION_TOTAL,
    REQUEST_WALLETS_CSV_DOWNLOADER_URL,
    SET_WALLETS_CSV_DOWNLOADER_URL,
    REQUEST_CANCEL_WALLETS_CSV_DOWNLOAD,
    SET_CANCEL_WALLETS_CSV_DOWNLOAD,
    SET_ALLOW_CSV_CANCEL
} from "@actions/actionTypes";
import {
    GetResource,
    DeleteResource,
    PutResource,
    PostResource
} from '../../utils/HttpUtil';
import {
    getQuery
} from "../../utils/CommonUtils";
import {onError, safe} from "./handlerError";
import {waitForFile} from "@services/CsvDownloadService";
import {getFormattedBody} from "@services/TableServices";
import "./csvProcess";
const API_URL = process.env.BONUS_SYSTEM_WALLETS_API_PATH;
let downloadTask

const takeLatest = (patternOrChannel, saga, ...args) => fork(function*() {
    while (true) {
        const action = yield take(patternOrChannel)
        if (patternOrChannel === REQUEST_WALLETS_CSV_DOWNLOADER_URL) {
            downloadTask = yield fork(saga, ...args.concat(action))
        } else {
            yield fork(saga, ...args.concat(action))
        }

    }
})

function* fetchWallets({params}) {
    let query = getQuery(params);
    const wallets = yield call(GetResource, `/Wallet?${query}`, API_URL);
    yield put({type: UPDATE_PAGINATION_TOTAL, total: wallets.totalCount});
    yield put({ type: SET_WALLETS, wallets: wallets.data? wallets.data : []});
}

function* fetchWalletsCsvDownloaderUrl({data, ...restProps}) {
    const checkProcessUrl = "/Wallet/getreporturl";
    let id = restProps.processId;
    if (!id) {
        const formattedData = getFormattedBody(data)
        const query = getQuery(formattedData)
        id = yield call(GetResource, `/Wallet/generatereport?${query}`, API_URL);
    }
    window.CsvProcess.setProcess({
        actionType: REQUEST_WALLETS_CSV_DOWNLOADER_URL,
        processId: id,
        checkProcessUrl
    })
    yield put({type: SET_ALLOW_CSV_CANCEL, allow: {allowWallets: true}})
    const link = yield waitForFile(REQUEST_WALLETS_CSV_DOWNLOADER_URL, id, checkProcessUrl, API_URL);
    yield put({type: SET_WALLETS_CSV_DOWNLOADER_URL, link})
    yield put({type: SET_ALLOW_CSV_CANCEL, allow: {allowWallets: false}})
    window.CsvProcess.removeProcess(id);

}

function* cancelWalletsCsvDownload(processId) {
    window.CsvProcess.setCanceled(processId);
    if (processId) {
        yield call(PutResource, `/Wallet/cancelreport?key=${processId}`, {}, API_URL)
    }
    yield delay(2000)
    yield put({type: SET_ALLOW_CSV_CANCEL, allow: {allowWallets: false}})
    yield put({type: SET_WALLETS_CSV_DOWNLOADER_URL, link: ""});
    yield put({type: SET_CANCEL_WALLETS_CSV_DOWNLOAD})
    window.CsvProcess.removeProcess(processId);
}

function* cancelFetching() {
    yield cancel(downloadTask);
    const processId = window.CsvProcess.getProcessIdByAction(REQUEST_WALLETS_CSV_DOWNLOADER_URL)
    yield cancelWalletsCsvDownload(processId)
}

function* walletsSaga() {
    yield takeLatest(REQUEST_WALLETS, safe(onError, fetchWallets));
    yield takeLatest(REQUEST_WALLETS_CSV_DOWNLOADER_URL, safe(onError, fetchWalletsCsvDownloaderUrl));
    yield takeLatest(REQUEST_CANCEL_WALLETS_CSV_DOWNLOAD, safe(onError, cancelFetching))
}
 
export default walletsSaga;