import React, { useEffect, useContext } from "react";
import {Form, Modal, Input, Switch, Select} from "antd";
import {FormattedMessage, injectIntl} from "react-intl";
import ImgUpload from '../ImgUpload/ImgUpload';
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import {
    REQUEST_ADD_DEPOSIT_GROUP
} from "@actions/actionTypes";
import ClientsSelect from "@components/ClientsSelect";


function DepositGroupsAddEditModal({show = true, clients, loading = false, edit = null, defaultSelectedClient, intl, onCancel, onFinish}) {

    const [form] = Form.useForm();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);

    useEffect(() => {
        subscribeForError(REQUEST_ADD_DEPOSIT_GROUP, () => {
            form.resetFields();
        });
        return () => {
            unSubscribeForError(REQUEST_ADD_DEPOSIT_GROUP);
        }
    }, [])

    useEffect(() => {
        if (show) {
            form.setFieldsValue({clientId: defaultSelectedClient});
        }
    }, [show, defaultSelectedClient])

    return (
        <Modal
            title={<FormattedMessage id={(edit? 'edit' : 'create') + '_group'}/>}
            centered
            visible={show}
            width={448}
            onCancel={() => {onCancel(); form.resetFields();}}
            className={`formModal ${loading ? "loading" : ""}`}
            footer={false}>
            <Form
                layout="vertical"
                form={form}
                name="group_form"
                className="group_form"
                validateTrigger="onBlur"
                onFinish={onFinish}>
                    <Form.Item
                        name="name"
                        label={<FormattedMessage id='group_name'/>}
                        rules={[
                            {
                                required: true,
                                message: <FormattedMessage id='group_name_validator'/>
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                   if (value && (value.length < 3 || value.length > 50)) {
                                        return Promise.reject(intl.formatMessage({id: `deposit_name_length_is_not_valid`}));
                                    }

                                    return Promise.resolve();
                                },
                            })
                        ]}
                    >
                        <Input placeholder={intl.formatMessage({id: `type_group_name`})}/>
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label={<FormattedMessage id='description'/>}
                        rules={[
                            {
                                required: true,
                                message: <FormattedMessage id='group_description_validator'/>
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                   if (value && (value.length < 3 || value.length > 50)) {
                                        return Promise.reject(intl.formatMessage({id: `description_is_not_valid`}));
                                    }

                                    return Promise.resolve();
                                },
                            })
                        ]}
                    >
                        <Input placeholder={intl.formatMessage({id: `type_group_description`})}/>
                    </Form.Item>
                    <Form.Item
                        name="clientId"
                        label={<FormattedMessage id='client'/>}
                        rules={[
                            {
                                required: true,
                                message: <FormattedMessage id='client_validator'/>
                            },
                        ]}
                    >
                        <Select getPopupContainer={triggerNode => triggerNode.parentElement}>
                        {clients.map(client => (
                            <Select.Option key={client.id}
                                        value={client.id}
                                        >{client.name}</Select.Option>
                        ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={<FormattedMessage id='select_status'/>}
                        className=""
                        name="status"
                        valuePropName="checked">
                        <Switch className="radioSwitch"/>
                    </Form.Item>
                    <Form.Item name="icon"
                            label={<FormattedMessage id='upload_logo'/>}
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id='group_image_validator'/>
                                },
                            ]}>
                        <ImgUpload/>
                    </Form.Item>
                    <Form.Item className="MainModal--footer">
                        <button type="reset" className="miniBtn default" onClick={onCancel}>
                            <FormattedMessage id='cancel'/>
                        </button>
                        <button type="submit" className="miniBtn">
                            <FormattedMessage id='save'/>
                        </button>
                    </Form.Item>
            </Form>
        </Modal>
    )
}

export default injectIntl(DepositGroupsAddEditModal);