import {
    SET_TRANSACTIONS
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function TransactionsReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_TRANSACTIONS:
            return action.transactions
        default:
            return state;
    }
}
