import React, {useEffect, useState} from 'react';
import { Menu } from 'antd';

  import { Link,  useLocation, useHistory } from "react-router-dom";
  import {FormattedMessage} from "react-intl";
  import "./style.sass";
  import depositCoupons from "@assets/img/icon/deposit_coupons.svg";
  import wallets from "@assets/img/icon/wallets.svg";
  import deposit from "@assets/img/icon/deposit.svg";
  import clientsIcon from "@assets/img/icon/clients.svg";
  import transactionsIcon from "@assets/img/icon/transactions.svg";

function LeftBare({user}) {
    const localHistory = useHistory();
    let location = useLocation();
    const [path, setPath] = useState(localHistory.location.pathname)

    useEffect(() => {
        let path = location.pathname;
        if (path.indexOf('deposit_groups_detals') !== -1) {
            path = '/deposit_groups';
        }
        setPath(path);
    },[location])

    const getPathParams = (path) => {
        let currentPath = location.pathname;
        let params = '';
        if (currentPath.indexOf(path) !== -1) {
            params = location.search;
        }
        return path + params;
    }

    return (
        <Menu  mode="inline" selectedKeys={[path]} className='left-bar-block'>
            <Menu.Item key="/deposit_groups" icon={<img src={depositCoupons}/>}>
                <Link  to={getPathParams("/deposit_groups")}><FormattedMessage id='deposit_groups' /></Link >
            </Menu.Item>
            <Menu.Item key="/wallets" icon={<img src={wallets}/>}>
                <Link  to={getPathParams("/wallets")}><FormattedMessage id='wallets' /></Link >
            </Menu.Item>
            {/* <Menu.Item key="/deposit" icon={<img src={deposit}/>} >
                <Link  to={getPathParams("/deposit")}><FormattedMessage id='deposit' /></Link >
            </Menu.Item> */}
            {
                user?.profile?.IsAdmin || user?.profile?.IsSuperAdmin? <Menu.Item key="/clients" icon={<img src={clientsIcon}/>}>
                <Link  to={getPathParams("/clients")}><FormattedMessage id='clients' /></Link >
            </Menu.Item> : ""
            }
            
            <Menu.Item key="/transactions" icon={<img src={transactionsIcon}/>}>
                <Link  to={getPathParams("/transactions")}><FormattedMessage id='transactions' /></Link >
            </Menu.Item>
        </Menu>
    )
}

export default LeftBare;