import React, {useEffect, useState} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Form, Input, Select, Tooltip} from "antd";
import {WalletsStatusType, WalletStatusTypeArr} from "@constants/Enums";
import {getFormattedForForm, getFormattedStateData} from "@services/walletsService";
import {getFormattedUrlObject, getUrlString, getUrlValue, getQuery, checkNumberValidation} from "../../utils/CommonUtils";
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import {
    getClientsList,
    clearClients
} from "@actions/clientsAction";

import {
    getWallets,
    getWalletsCsvLink,
    cancelWalletsCsvDownload
} from "@actions/walletsAction";
import {
    setPagination
} from "@actions/paginationsActions"
import CsvDownloader from "@components/CSVDownloader/CsvDownloader";

function WaletsFilter({intl, showFilter, user, clientSelect, loading, pagination, getClientsList, clearClients, getWalletsCsvLink, cancelWalletsCsvDownload, allowCancel}) {
    const [isDisableProductKeySelect, setIsDisableProductKeySelect] = useState(true);
    let history = useHistory();

    const [form] = Form.useForm();
    const [products, setProducts] = useState([]);

    const initFormData = (loc) => {
        const urlObj = getUrlValue(loc);
        let initialForm = getFormattedUrlObject(urlObj);

        if (!(user?.profile?.IsAdmin || user?.profile?.IsSuperAdmin)) {
            initialForm.clientId = user?.profile.sub;
            onChangeClient(initialForm.clientId);
        }
        form.resetFields();
        form.setFieldsValue(getFormattedForForm(initialForm));
    }

    useEffect(() => {
        getClientsList('', '', true);
        const loc = history && history.location && history.location.search;
        initFormData(loc);
        return () => {
            clearClients();
        }
    }, [])

    const finishFilter = (data) => {
        const {params} = getUrlString({
            ...getFormattedStateData(data),
            page: 1,
            limit: Math.max(10, pagination.limit)
        });
        history.push({search: params.toString()});
    }

    const resetFilter = () => {
        form.resetFields();
        if (user.profile.IsAdmin || user.profile.IsSuperAdmin) {
            setProducts([]);
            setIsDisableProductKeySelect(true);
        } else {
            form.setFieldsValue({clientId: user.profile.sub});
        }
    }

    const exportCVS = () => {
        if (loading._wallets_csv_downloader_url === undefined || !loading._wallets_csv_downloader_url) {
            getWalletsCsvLink(form.getFieldsValue())
        }
    }

    useEffect(() => {
        onChangeClient(form.getFieldValue('clientId'));
    }, [clientSelect])

    const onChangeClient = (clientId) => {
        const selectedClient = clientSelect.find(client => client.id === clientId);
        setProducts(selectedClient?.products || []);
        setIsDisableProductKeySelect(!selectedClient);
    }

    useEffect(() => {
        const loc = history && history.location && history.location.search;
        const urlObj = getUrlValue(loc);
        if (urlObj.clientId != form.getFieldValue('clientId')) {
            form.resetFields(['productKey']);
        }
    }, [products])

    return (
        <div className="searchBar ">
            <Form
                form={form}
                onFinish={finishFilter}
                className="wallets-filter"
                layout="vertical">
                <div className={classNames("searchBar--row",{show: true})}>
                    <div className={classNames("searchBar--col", {hidden: !(user?.profile?.IsAdmin || user?.profile?.IsSuperAdmin)})}>
                        <Form.Item
                            label={<FormattedMessage id={"client"}/>}
                            name="clientId"
                            >
                            <Select allowClear className="client-list-select" onChange={onChangeClient} getPopupContainer={triggerNode => triggerNode.parentElement}>
                                {clientSelect.map(client => (
                                    <Select.Option key={client.id} value={client.id}>{client.name}</Select.Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            label={<FormattedMessage id={"product"}/>} name="productKey">
                            <Select allowClear
                                    showSearch={true}
                                    disabled={isDisableProductKeySelect}
                                    className="product-list-select"
                                    placeholder={intl.formatMessage({id: "select_product"})}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    getPopupContainer={triggerNode => triggerNode.parentElement}>
                                {products.map(product => (
                                    <Select.Option key={product.id} value={product.key}>{product.name}</Select.Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item
                            name="playerName"
                            label={<FormattedMessage id={"player_name"}/>}>
                            <Input placeholder={intl.formatMessage({id: "type_player_name"})}/>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item className={"numberRangeInput"} label={<FormattedMessage id={"amount"}/>}>
                            <Form.Item name="amountRangeStart" noStyle>
                                <Input placeholder={intl.formatMessage({id: "from"})} onKeyDown={checkNumberValidation} />
                            </Form.Item>
                            <div className="separator"/>
                            <Form.Item name="amountRangeEnd" noStyle>
                                <Input placeholder={intl.formatMessage({id: "to"})} onKeyDown={checkNumberValidation} />
                            </Form.Item>
                        </Form.Item>
                    </div>
                    <div className="searchBar--col">
                        <Form.Item label={<FormattedMessage id={"status"}/>} name="statuses">
                            <Select mode="multiple"
                                    optionLabelProp="label"
                                    showArrow showSearch={false}
                                    dropdownClassName="statusDropDown"
                                    getPopupContainer={triggerNode => triggerNode.parentElement}>
                                {Object.values(WalletsStatusType).filter((_, index) => index > 0).map(status => (
                                    <Select.Option className={WalletStatusTypeArr[status]}
                                                    key={status}
                                                    value={""+status}
                                                    label={
                                                        <span className={WalletStatusTypeArr[status]}>
                                                            <FormattedMessage id={WalletStatusTypeArr[status]}/>
                                                            </span>
                                                        }>
                                        <FormattedMessage id={WalletStatusTypeArr[status]}/>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="searchBar--col btnBox" style={{width: 329, maxWidth: 330}}>
                        <div className="filterPart">
                            <button type="button" className="miniBtn default small" onClick={resetFilter}>
                                <FormattedMessage id='clear'/>
                            </button>
                            <button type="submit" className="miniBtn small" style={{margin: 0}}>
                                <FormattedMessage id='search'/>
                            </button>
                        </div>
                        <div className="exportPart" style={{minWidth: 100}}>
                            <CsvDownloader exportCVS={exportCVS}
                                           downloadLoading={loading._wallets_csv_downloader_url}
                                           cancelLoading={loading._cancel_wallets_csv_download}
                                           onCancel={cancelWalletsCsvDownload}
                                           allowCancel={allowCancel}/>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        filter: state.betsListFilter,
        clientSelect: state.clients,
        pagination: state.pagination,
        loading: state.loading,
        allowCancel: state.csvAllowCancel.allowWallets,
        user: state.user
    }
}

const mapDispatchToProps = {
    getClientsList,
    clearClients,
    getWallets,
    getWalletsCsvLink,
    cancelWalletsCsvDownload
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WaletsFilter));