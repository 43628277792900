import { GetToken, Logout, CompleteLogout } from "@services/AuthService";
import { DefaultLanguage, LanguageCodesArr, LanguageForApi } from "@constants/Enums";

const API_URL = process.env.BONUS_SYSTEM_API_PATH;
// export const IDP_URL = `${process.env.REACT_APP_API_IDP_PATH}`;

export async function GetResource(route, api, headerProperty = {}) {
  const url = new URL(`${api || API_URL}${route}`);
  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${GetToken()}`,
      ...headerProperty,
    },
  });

  if (res.status == 401) {
    CompleteLogout();
    throw res.status;
  }

  if (res.status === 404) return {};
  let body;
  try {
    body = res.body ? await res.json() : null;
  } catch (e) {
    body = [];
  }
  if (!res.ok)
    setTimeout(() => {
      throw body.error || res.status;
    }, 10);
  const data = body && body.data !== undefined ? body.data : body;
  if (body.totalCount || body.totalCount === 0) {
    return { totalCount: body.totalCount, data };
  }
  if (body.hasNext != null) {
    return { hasNext: body.hasNext, data };
  }
  return data;
}

export async function DeleteResource(route, data, api, headerProperty = {}) {
  const url = new URL(`${api || API_URL}${route}`);
  const fetchBody = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${GetToken()}`,
      "content-type": "application/json; charset=utf-8",
      ...headerProperty,
    },
  };
  if (data) {
    fetchBody.body = JSON.stringify(data);
  }
  const res = await fetch(url, { ...fetchBody });

  if (res.status === 204) {
    return true;
  }
}

export async function PostResource(route, data, url, headerProperty = {}) {
  if (!url) url = `${API_URL}${route}`;
  else url = `${url}${route}`;
  const res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${GetToken()}`,
      ...headerProperty,
    },
    body: JSON.stringify(data),
  });
  if (res.status == 403 || res.status == 401) {
    CompleteLogout();
    throw res.status;
  }
  const body = await res.json();
  if (!res.ok) {
    if (body && body.length > 0) {
      let errors = [];
      body.forEach((item) => {
        errors.push(item.error);
      });
      throw errors;
    } else {
      throw body.error || res.status;
    }
  }
  return body;
}

export async function PutResource(route, data, url, headerProperty = {}) {
  if (!url) url = `${API_URL}${route}`;
  else url = `${url}${route}`;
  const res = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${GetToken()}`,
      ...headerProperty,
    },
    body: JSON.stringify(data),
  });
  if (res.status == 403 || res.status == 401) {
    CompleteLogout();
    throw res.status;
  }
  const body = await res.json();

  if (body.statusCode != 200) {
    if (body.statusCode === 30) {
      const processId = url.split("=")[1];
      if (!window.CsvProcess.checkIsProcessHasLink(processId)) {
        setTimeout(() => {
          throw body.statusCode;
        }, 10);
      }
    } else {
      setTimeout(() => {
        throw body.statusCode;
      }, 10);
    }
  }

  if (!res.ok) {
    if (body && body.length > 0) {
      let errors = [];
      body.forEach((item) => {
        errors.push(item.error);
      });
      throw errors;
    } else {
      throw body.error || res.status;
    }
  }
  return body;
}

export async function UploadFile(file) {
  const formData = new FormData();
  formData.append("file", file);
  return fetch(`${API_URL}/file`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${GetToken()}`,
    },
    body: formData,
  });
}

export function correctedBySend(data) {
  let copyData = JSON.parse(JSON.stringify(data));
  if (!copyData.id) delete copyData.id;
  copyData.translations = [];
  LanguageCodesArr.forEach((lang) => {
    if (copyData[lang]) {
      copyData.translations.push({
        lang: LanguageForApi[lang],
        name: copyData[lang],
      });
      delete copyData[lang];
    }
  });
  if (copyData.translations.length === 0) delete copyData.translations;
  return copyData;
}

export function encodeRequestBody(data) {
  return Object.keys(data)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
    })
    .join("&");
}
