import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
    REQUEST_PRODUCTS,
    SET_PRODUCTS
} from "@actions/actionTypes";
import {
    GetResource,
    DeleteResource,
    PutResource,
    PostResource
} from '../../utils/HttpUtil';
import {onError, safe} from "./handlerError";
const API_URL = process.env.BONUS_SYSTEM_DEPOSIT_API_PATH;

function* fetchProducts({clientId}) {
    const clients = yield call(GetResource, `/Client`, API_URL);
    const currentClient = clients.find(client => client.id == clientId)
    yield put({ type: SET_PRODUCTS, products: currentClient.products });
}

function* productsSaga() {
    yield takeLatest(REQUEST_PRODUCTS, safe(onError, fetchProducts));
}
 
export default productsSaga;