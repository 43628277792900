import {
    SET_USERS
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function UsersReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_USERS:
            return action.users
        default:
            return state;
    }
}
