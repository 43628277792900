import moment from "moment-timezone";

export default {
    settings: {
        lang: {
            id: 1
        }
    },
    user: null,
    emeptyObj: {},
    emptyArr: [],
    pagination: {
        page: 1,
        limit: 20,
        total: 5000
    },
    playersList: {
        list: [],
        listModalVisible: false,
        isLoading: false
    },
    wallets: [
        {
            clientName: 'client 1',
            group: "deposit group",
            wagering: 10,
            usedUp: 100,
            use: 1000,
            amount: 200,
            expiresIn: moment(+(new Date()) + 1200000).format(),
            given: moment().format(),
            status: 2,
            player: "sss fdasef sdfdsaf"
        },
        {
            clientName: 'client 2',
            group: "deposit group",
            wagering: 10,
            usedUp: 200,
            use: 1000,
            amount: 200,
            expiresIn: moment(+(new Date()) + 1200000).format(),
            given: moment().format(),
            status: 1,
            player: "sss fdasef sdsaf"
        },
        {
            clientName: 'client 4',
            group: "deposit group",
            wagering: 10,
            usedUp: 100,
            use: 1500,
            amount: 200,
            expiresIn: moment(+(new Date()) + 1200000).format(),
            given: moment().format(),
            status: 0,
            player: "sss fdasef sdfdsaf"
        },
        {
            clientName: 'client 1',
            group: "deposit group",
            wagering: 10,
            usedUp: 1000,
            use: 1000,
            amount: 200,
            expiresIn: moment(+(new Date()) + 1200000).format(),
            given: moment().format(),
            status: 2,
            player: "sss asef sdfdsaf"
        }
    ],
    clients: [
            {
              "id": "310638aa-894d-4c69-9fb8-85481f86122b",
              "name": "Active Bets1"
            },
            {
              "id": "e3f83af9-0095-40ff-acc3-62be2ac0d2b9",
              "name": "Bet page"
            },
            {
              "id": "899d4b91-06ac-49a6-b414-d674dae658dc",
              "name": "Binary bet"
            },
            {
              "id": "4ec2bfb8-695b-4be0-adcf-02086e219224",
              "name": "Binary test"
            },
            {
              "id": "d77cd593-17a7-47b8-836b-a4da5c676dc0",
              "name": "Binary3 test"
            },
            {
              "id": "ef79152f-9aab-405e-a689-056171716f8f",
              "name": "Client Stage"
            },
            {
              "id": "fb5be864-7cdd-46b2-b66e-153c6f5d05ea",
              "name": "Eight Test8"
            },
            {
              "id": "e658f442-7619-478e-9ff2-c133d8027293",
              "name": "Eighteen Test"
            },
            {
              "id": "88bda455-90cb-4103-a612-6929529a056c",
              "name": "Eleven Test12"
            },
            {
              "id": "5c5e08c3-7982-4fcd-af3f-55b2357a90b5",
              "name": "Fifteen Test"
            },
            {
              "id": "aacdb3db-d249-4f4d-9ad1-90b3320a1206",
              "name": "First asset"
            },
            {
              "id": "f7b049c0-c83f-4d43-8796-07c02dc6e925",
              "name": "Five Test5"
            },
            {
              "id": "c32f202d-3a31-4af8-beaf-ea91e8692f7f",
              "name": "Four Test1"
            },
            {
              "id": "6b30a1a1-a28c-4714-8281-1fb92376ce5a",
              "name": "Fourteen-Test"
            },
            {
              "id": "923cce6b-f30b-4a27-9e33-f2dc3ec4a84f",
              "name": "Human test"
            },
            {
              "id": "65bee27b-922d-42bc-80b2-ad932afc7b5d",
              "name": "New test"
            },
            {
              "id": "b245815e-7b3b-4224-9961-e8ab65e02cd7",
              "name": "Nine Test1"
            },
            {
              "id": "3ccc60e2-19f8-4504-904a-c6ab6f436c2e",
              "name": "One test1"
            },
            {
              "id": "f086d105-3bc5-4b6f-a24f-157cf6b7d632",
              "name": "Second Test21"
            },
            {
              "id": "ea3f9e2e-bcac-4f40-bb8f-12b6af510413",
              "name": "Seven Test7"
            },
            {
              "id": "3ae2305f-c7fc-4c2c-96de-bc12a3717532",
              "name": "Seventeen Test"
            },
            {
              "id": "af4ac3cf-b4b6-4c73-8855-b55199b2cca9",
              "name": "Six Test6"
            },
            {
              "id": "c4cb910f-506e-44c0-b318-aba06395e2ff",
              "name": "Sixteen Test"
            },
            {
              "id": "46998b21-e351-4030-b247-918ca32e6e80",
              "name": "Ten Test10"
            },
            {
              "id": "1ae54fe3-47ff-4df2-8f8d-bf6dc111fe02",
              "name": "Test1"
            },
            {
              "id": "d41c6c03-69a2-4fde-8477-2d84b00c4a5f",
              "name": "Test11"
            },
            {
              "id": "4a52cbbe-984a-4bd6-b4e1-babc86076064",
              "name": "Test21"
            },
            {
              "id": "f20c7545-4fc5-4b29-a90b-8b7c4d76fb4f",
              "name": "Test22"
            },
            {
              "id": "ee6c5ad0-8a55-4746-b4a9-a4fb3df4a01b",
              "name": "Test23"
            },
            {
              "id": "4fa7ac56-814b-48d6-8a4d-c1e89d783291",
              "name": "Test3"
            },
            {
              "id": "5bfb7b3b-d2a5-4b52-a3ae-8fd3a55df15d",
              "name": "Thirteen Test13"
            },
            {
              "id": "98ce218a-d802-4a41-ac6e-f5070ea08bfa",
              "name": "Three Test1"
            },
            {
              "id": "de7f6581-ccbe-4869-ad50-4d716be5520e",
              "name": "Three Test31"
            },
            {
              "id": "76b4ded9-595b-4651-b6fd-1527c7df7ab2",
              "name": "Twelve Test"
            },
            {
              "id": "3e6e9931-c7da-47b0-8c96-31062c14767b",
              "name": "Twelve Test121"
            },
            {
              "id": "e672534d-60a6-4ec8-ab68-4bbdd169c62e",
              "name": "Twenty Currency test"
            },
            {
              "id": "5fe8f278-7749-474b-8aa1-0b42619ca22b",
              "name": "Twenty One Currency"
            },
            {
              "id": "7ae2a030-f364-469d-8adf-3e64c4b6bef7",
              "name": "UNIT NAME"
            },
            {
              "id": "981b91e7-e3bb-45af-9555-f6a545133484",
              "name": "wer624dgt wer624dgt wer624dgt wer624dgt "
            }
    ],
    allowCancel: {
        allowWallets: false,
        allowDeposits: false,
        allowTransaction: false
    },
}