import { call, put, delay, take, fork, cancel } from "redux-saga/effects";
import { onError, safe } from "./handlerError";
import { GetResource, PutResource } from "../../utils/HttpUtil";
import { getQuery } from "../../utils/CommonUtils";
import {
  REQUEST_TRANSACTIONS,
  SET_TRANSACTIONS,
  UPDATE_PAGINATION_TOTAL,
  REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL,
  SET_TRANSACTIONS_CSV_DOWNLOADER_URL,
  REQUEST_CANCEL_TRANSACTIONS_CSV_DOWNLOAD,
  SET_CANCEL_TRANSACTIONS_CSV_DOWNLOAD,
  SET_ALLOW_CSV_CANCEL,
} from "@actions/actionTypes";
import { waitForFile } from "@services/CsvDownloadService";
import { getFormattedBody } from "@services/TableServices";
import "./csvProcess";
const API_URL = process.env.BONUS_SYSTEM_WALLETS_API_PATH;
let downloadTask;

const takeLatest = (patternOrChannel, saga, ...args) =>
  fork(function* () {
    while (true) {
      const action = yield take(patternOrChannel);
      if (patternOrChannel === REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL) {
        downloadTask = yield fork(saga, ...args.concat(action));
      } else {
        yield fork(saga, ...args.concat(action));
      }
    }
  });

function* fetchTransactions({ params }) {
  let query = getQuery(params);
  const transactions = yield call(GetResource, `/Transaction?${query}`, API_URL);
  yield put({ type: UPDATE_PAGINATION_TOTAL, total: transactions.totalCount });
  yield put({ type: SET_TRANSACTIONS, transactions: transactions.data ? transactions.data : [] });
}

function* fetchTransactionsCsvDownloaderUrl({ body, ...restProps }) {
  const checkProcessUrl = "/transaction/getreporturl";
  let id = restProps.processId;
  if (!id) {
    const query = getQuery(body);
    id = yield call(GetResource, `/transaction/generatereport?${query}`, API_URL);
  }
  window.CsvProcess.setProcess({
    actionType: REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL,
    processId: id,
    checkProcessUrl,
  });
  yield put({ type: SET_ALLOW_CSV_CANCEL, allow: { allowTransaction: true } });
  const link = yield waitForFile(REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL, id, checkProcessUrl, API_URL);
  yield put({ type: SET_TRANSACTIONS_CSV_DOWNLOADER_URL, link });
  yield put({ type: SET_ALLOW_CSV_CANCEL, allow: { allowTransaction: false } });
  window.CsvProcess.removeProcess(id);
}

function* cancelTransactionCsvDownload(processId) {
  window.CsvProcess.setCanceled(processId);
  if (processId) {
    yield call(PutResource, `/transaction/cancelreport?key=${processId}`, {}, API_URL);
  }
  yield delay(2000);
  yield put({ type: SET_ALLOW_CSV_CANCEL, allow: { allowTransaction: false } });
  yield put({ type: SET_TRANSACTIONS_CSV_DOWNLOADER_URL, link: "" });
  yield put({ type: SET_CANCEL_TRANSACTIONS_CSV_DOWNLOAD });
  window.CsvProcess.removeProcess(processId);
}

function* cancelFetching() {
  yield cancel(downloadTask);
  const processId = window.CsvProcess.getProcessIdByAction(REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL);
  yield cancelTransactionCsvDownload(processId);
}

function* transactionsSaga() {
  yield takeLatest(REQUEST_TRANSACTIONS, safe(onError, fetchTransactions));
  yield takeLatest(REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL, safe(onError, fetchTransactionsCsvDownloaderUrl));
  yield takeLatest(REQUEST_CANCEL_TRANSACTIONS_CSV_DOWNLOAD, safe(onError, cancelFetching));
}

export default transactionsSaga;

///window.open(`${csvLink}`, "_blank")
