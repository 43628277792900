import {
    REQUEST_WALLETS,
    CLEAR_WALLETS,
    REQUEST_WALLETS_CSV_DOWNLOADER_URL,
    REQUEST_CANCEL_WALLETS_CSV_DOWNLOAD
} from "@actions/actionTypes";

export function getWallets(params) {
    return dispatch => {
        dispatch({
            type: REQUEST_WALLETS,
            params
        })
    }
}

export function clearWallets() {
    return dispatch => {
        dispatch({
            type: CLEAR_WALLETS
        })
    }
}

export function getWalletsCsvLink(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_WALLETS_CSV_DOWNLOADER_URL,
            data
        })
    }
}

export function cancelWalletsCsvDownload() {
    return dispatch => {
        dispatch({
            type: REQUEST_CANCEL_WALLETS_CSV_DOWNLOAD
        })
    }
}
