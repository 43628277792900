import {
    REQUEST_TRANSACTIONS,
    REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL,
    REQUEST_CANCEL_TRANSACTIONS_CSV_DOWNLOAD
} from "@actions/actionTypes";

export function getTransactions(params) {
    return dispatch => {
        dispatch({
            type: REQUEST_TRANSACTIONS,
            params
        })
    }
}

export function getTransactionsCsvLink(body) {
    return dispatch => {
        dispatch({
            type: REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL,
            body
        })
    }
}

export function cancelTransactionCsvDownload() {
    return dispatch => {
        dispatch({
            type: REQUEST_CANCEL_TRANSACTIONS_CSV_DOWNLOAD
        })
    }
}
