import {combineReducers} from "redux";
import settings from "./settingsReducer";
import {
    userReducer as user,
    isEndSilentRequestReducer as isEndSilentRequest
} from "./userReducer";
import error from "./errorReducer";
import langList from "./langListReducer";
import players from "./playersReducer";

import {
    couponsReducer as coupons,
    couponReducer as coupon,
    couponSelectReducer as couponSelect
} from "./couponsReducer";
import depositGroups from "./depositGroupsReducer";
import loading from "./loadingReducer";
import pagination from "./paginationReducer";
import selectedGroup from "./selectedGroupReducer";
import {
    couponHistoryReducer as couponHistory,
    historyPaginationReducer as historyPagination
} from "./couponHistoryReducer";
import wallets from "./walletsReducer";
import clients from "./clientsReducer";
import deposits from "./depositsReducer";
import responseErrors from "./responseErrorsReducer";
import products from "./productsReducer";
import transactions from "./transactionsReducer";
import path from "./pathReducer";
import csvAllowCancel from "./csvAllowCancelReducer";
import users from "./usersReducer";

const rootReducer = combineReducers({
    settings,
    user,
    isEndSilentRequest,
    langList,
    error,
    players,

    coupons,
    coupon,
    couponSelect,
    depositGroups,
    loading,
    pagination,
    selectedGroup,
    couponHistory,
    historyPagination,
    wallets,
    clients,
    deposits,
    responseErrors,
    products,
    transactions,
    path,
    csvAllowCancel,
    users
});

export default rootReducer;
