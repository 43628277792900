import initialStore from "../initialStore";
import {
    SET_DEPOSIT_GROUP,
    SET_ON_OFF_GROUP,
    SET_EDIT_DEPOSIT_GROUP
} from "@actions/actionTypes";

export default function selectedGroupReducer(state = initialStore.emeptyObj, action) {
    switch (action.type) {
        case SET_DEPOSIT_GROUP:
            return  action.group;
        case SET_ON_OFF_GROUP:
        case SET_EDIT_DEPOSIT_GROUP:
            if (state.id === action.data.id) {
                return action.data;
            }
            return state;
        default:
            return state;
    }
}