import React, { useEffect, useState } from "react";
import {Row, Skeleton} from "antd";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {
    getGroups
} from "@actions/depositGroupsAction";
import EmptyGroupsGrid from "./EmptyGroupsGrid";

function DepositGroupsLoading() {
    const items = [...Array(20).keys()];

    return (
        items.map(i => (
            <div key={i} className="group-card">
                <div className="img-block">
                    <Skeleton.Image />
                </div>
                <div className="group-text">
                    <Skeleton.Input style={{width: 93, height: 20}} active={true} size='default'/>
                    <Skeleton.Input style={{width: '100%', height: 20}} active={true} size='default'/>
                </div>
            </div>
        ))
    )
}

function DepositGroupsGrid({depositGroups, loading, clientId, getGroups, setShowModal, disableLoading}) {

    const [isFirstCall, setIsFirstCall] = useState(true);

    useEffect(() => {
        if (clientId) {
            getGroups(clientId);
            setIsFirstCall(false);
        }
    }, [clientId])

    return (
        <>
        {
            !disableLoading && (loading._deposit_groups == undefined || loading._deposit_groups || isFirstCall) ? 
                <DepositGroupsLoading /> : depositGroups.length == 0 ?
                    <EmptyGroupsGrid setShowModal={setShowModal}/> :
                    <>
                        {depositGroups.map(group => (
                            <Link key={group.id} className="group-card" to={`deposit_groups_detals/${group.id}/${clientId}`}>
                                <div className="img-block">
                                    <img src={group.icon} />
                                </div>
                                <div className="group-text">
                                    <h3>{group.name}</h3>
                                    <p>{group.description}</p>
                                </div>
                            </Link>
                        ))}
                    </>
        }
            
        </>
    )
}
function mapStateToProps(state) {
    return {
        depositGroups: state.depositGroups,
        loading: state.loading
    }
}

const mapDispatchToProps = {
    getGroups
}

export default connect(mapStateToProps, mapDispatchToProps)(DepositGroupsGrid);