import React, { useEffect, useState, useContext } from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import DepositGroupsGrid from "@components/DepositGroupsGrid/DepositGroupsGrid";
import EmptyGroupsGrid from "@components/DepositGroupsGrid/EmptyGroupsGrid";
import DepositGroupsAddEditModal from "@components/DepositGroupsAddEditModal";
import {
    REQUEST_ADD_DEPOSIT_GROUP,
    REQUEST_CLIENTS
} from "@actions/actionTypes";
import {
    addDepositGroup,
    clearDepositGroups
} from "@actions/depositGroupsAction";
import {
    getClientsList
} from "@actions/clientsAction";
import {
    ResponseContext
} from "../../../hooks/ResponseObserver";
import ClientsSelect from "@components/ClientsSelect";
import {
    getUrlValue,
    getUrlString
} from "../../../utils/CommonUtils";
import {PlusCircleTwoTone} from "@ant-design/icons";
import "./style.sass";

function DepositCoupons({loading, clients, addDepositGroup, getClientsList, clearDepositGroups, depositGroups}) {
    let history = useHistory();
    const search = history && history.location && history.location.search

    const [showModal, setShowModal] = useState(false);
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    const [clientId, setClientId] = useState(getUrlValue(search).clientId || '');
    const [isFetchFinished, setIsFetchFinished] = useState(false);
    const [prevClientsList, setPrevClientsList] = useState();

    const pushHistory = (clientId, isFirst) => {
        const {params} =  getUrlString({clientId});
        isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()});
    }

    useEffect(() => {
        subscribeForError(REQUEST_ADD_DEPOSIT_GROUP, () => {
            setShowModal(false);
        });
        subscribeForError(REQUEST_CLIENTS, () => {
            setIsFetchFinished(true);
        });
        getClientsList('', '', true);
        return () => {
            unSubscribeForError(REQUEST_ADD_DEPOSIT_GROUP);
            unSubscribeForError(REQUEST_CLIENTS);
            clearDepositGroups();
        }
    }, [])

    useEffect(() => {
        if (!clientId && clients.length && isFetchFinished) {
            pushHistory(clients[0].id, true);
        }
        return () => {
            setPrevClientsList(clients);
        }
    }, [clients])

    useEffect(() => {
        if (!clientId && clients.length && isFetchFinished) {
            requestAnimationFrame(() => {
                pushHistory(clients[0].id, true);
            })
        }
    }, [clientId])

    useEffect(() => {
        return history.listen((location, action) => {
            const loc = location.search;
            const {clientId} = getUrlValue(loc);
            if (loc) {
                setClientId(clientId);
            } else {
                setClientId('')
            }
        })
    },[history])

    const onSave = (data) => {
        let newdate = data;
        let cId = newdate.clientId;
        delete newdate.clientId;
        const isCurrentClient = (cId === clientId);
        addDepositGroup(data, cId, isCurrentClient);
    }

    return <div className="deposit-groups">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='deposit_groups'/></div>
                <div className="titleArea--BtnGroup">
                    <ClientsSelect onChange={(clientId, _) => pushHistory(clientId)} selectedClient={getUrlValue(search).clientId} value={clientId || getUrlValue(search).clientId}/>
                    {depositGroups.length > 0 &&
                        <button className="mainBtn" onClick={() => setShowModal(true)}>
                            <PlusCircleTwoTone />
                            <FormattedMessage id='create_group'/>
                        </button>
                    }
                    
                </div>
            </div>
            <div className="data-grid">
                <DepositGroupsGrid clientId={clientId} setShowModal={setShowModal} disableLoading={prevClientsList && prevClientsList.length == 0 && clients.length == 0}/>
            </div>
            <DepositGroupsAddEditModal clients={clients}
                defaultSelectedClient={clientId}
                show={showModal}
                onFinish={onSave}
                onCancel={() => setShowModal(false)}
                loading={loading._deposit_group}/>
        </div>
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        clients: state.clients,
        depositGroups: state.depositGroups
    }
}

const mapDispatchToProps = {
    addDepositGroup,
    getClientsList,
    clearDepositGroups
}

export default connect(mapStateToProps, mapDispatchToProps)(DepositCoupons);