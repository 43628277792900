export function getFormattedStateData(data) {
    return {
        clientId: data.clientId ? data.clientId : "",
        clientName: data.clientName ? data.clientName : ""
    }
}

export const getFormattedForForm = (data) => {
    const initialData = {}
    Object.keys(data).forEach(key => {
        if (typeof  data[key] === "object") {
            if (key.indexOf("date") > -1) {
                (!initialData[key]) && (initialData[key] = []);
                initialData[`${key}`][0] =  moment(data[key].start)
                initialData[`${key}`][1] =  moment(data[key].end)
            } else {
                initialData[`${key}Start`] = data[key].start
                initialData[`${key}End`] = data[key].end
            }
        } else {
            initialData[key] = data[key]
        }
    })
    return initialData
}