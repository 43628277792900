import React from "react"
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Button} from "antd"



function HeaderButtons({buttonsDisabled, saveChange, cancelChange, loading}) {

    return (
        <div className="header-buttons">
            <button className="miniBtn default"
                    disabled={buttonsDisabled}
                    onClick={() => cancelChange()}>
                <FormattedMessage id='cancel'/>
            </button>
            <Button className="miniBtn" type="primary"
                disabled={buttonsDisabled && !loading._update_deposit_on}
                loading={loading._update_deposit_on}
                onClick={() => saveChange()}>
                <FormattedMessage id='save'/>
            </Button>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading
    }
}

const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderButtons);