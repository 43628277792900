import {
    REQUEST_DEPOSIT_GROUPS,
    REQUEST_ADD_DEPOSIT_GROUP,
    REQUEST_ON_OFF_GROUP,
    REQUEST_DEPOSIT_GROUP,
    REQUEST_EDIT_DEPOSIT_GROUP,
    CLEAR_DEPOSIT_GROUPS
} from "@actions/actionTypes";

export function getGroups(clientId) {
    return dispatch => {
        dispatch({
            type: REQUEST_DEPOSIT_GROUPS,
            clientId
        });
    }
}

export function getGroup(id, clientId) {
    return dispatch => {
        dispatch({
            type: REQUEST_DEPOSIT_GROUP,
            id,
            clientId
        });
    }
}

export function addDepositGroup(group, clientId, isCurrentClient) {
    return dispatch => {
        dispatch({
            type: REQUEST_ADD_DEPOSIT_GROUP,
            group,
            clientId,
            isCurrentClient,
            isOpenedByPopup: true
        });
    }
}

export function toggleGroupStatus(group, clientId) {
    return dispatch => {
        dispatch({
            type: REQUEST_ON_OFF_GROUP,
            data: group,
            clientId
        });
    }
}

export function editGroup(group, clientId) {
    return dispatch => {
        dispatch({
            type: REQUEST_EDIT_DEPOSIT_GROUP,
            data: group,
            clientId
        });
    }
}

export function clearDepositGroups() {
    return dispatch => {
        dispatch({
            type: CLEAR_DEPOSIT_GROUPS
        });
    }
}