import { call, put, takeLatest } from "redux-saga/effects";
import {
  REQUEST_ADD_CLIENT,
  REQUEST_CLIENTS,
  SET_CLIENTS,
  REQUEST_EDIT_CLIENT,
  SET_EDIT_CLIENT,
  SET_ERROR_MSG,
  SET_ADD_CLIENT,
  CHANGE_LOADING,
} from "@actions/actionTypes";
import { GetResource, PostResource, PutResource } from "../../utils/HttpUtil";
import { safe, onError } from "./handlerError";
const API_URL = process.env.BONUS_SYSTEM_DEPOSIT_API_PATH;
let prevNameLike = "",
  prevSortDirDesc = "";

function* fetchClients({ nameLike, sortDirDesc, isSortByName }) {
  nameLike ? (prevNameLike = nameLike) : (prevNameLike = "");
  sortDirDesc && (prevSortDirDesc = sortDirDesc);
  let clientsResponse = yield call(GetResource, `/Client?${nameLike ? "nameLike=" + encodeURIComponent(nameLike) : ""}`, API_URL); //&sortDirDesc=${sortDirDesc}
  let clients = clientsResponse.data;
  if (isSortByName && clients.length) {
    clients = clients.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }
  yield put({ type: SET_CLIENTS, clients: clients.length ? clients : [] });
  if (!clients.length) {
    yield put({ type: CHANGE_LOADING, loading: { _deposit_groups: false } });
  }
}

function* addClient({ body }) {
  const client = yield call(PostResource, `/Client`, { ...body, isActive: body.isActive ? true : false }, API_URL);
  yield put({
    type: REQUEST_CLIENTS,
    nameLike: encodeURIComponent(prevNameLike),
    sortDirDesc: prevSortDirDesc,
  });
  yield put({ type: SET_ADD_CLIENT, client });
  yield put({ type: SET_ERROR_MSG, data: { type: "done", messages: "success" } });
}

function* editClient({ data }) {
  const client = yield call(PutResource, `/Client/${data.id}`, data, API_URL);
  yield put({
    type: SET_EDIT_CLIENT,
    data: client.data,
  });
  yield put({ type: SET_ERROR_MSG, data: { type: "done", messages: "success" } });
}

function* clientsSaga() {
  yield takeLatest(REQUEST_CLIENTS, safe(onError, fetchClients));
  yield takeLatest(REQUEST_ADD_CLIENT, safe(onError, addClient));
  yield takeLatest(REQUEST_EDIT_CLIENT, safe(onError, editClient));
}

export default clientsSaga;
