import { call, put, all, fork, take, cancel } from 'redux-saga/effects';
import {
    REQUEST_COUPONS_HISTORY,
    SET_COUPONS_HISTORY,
    UPDATE_HISTORY_PAGINATION,
    RESET_COUPONS_HISTORY,
    UPDATE_PAGINATION,
    UPDATE_BY_START_PAGINATION
} from "@actions/actionTypes";
import {
    GetResource,
    DeleteResource,
    PutResource,
    PostResource
} from '../../utils/HttpUtil';
import {onError, safe} from "./handlerError";
import initialStore from '../initialStore';
import {
    grtParamsQueryByFilter
} from "../../utils/CommonUtils";


let lastTask

const takeLatest = (patternOrChannel, saga, ...args) => fork(function*() {
    lastTask
    while (true) {
      const action = yield take(patternOrChannel)
      if (lastTask) {
        yield cancel(lastTask) // cancel is no-op if the task has already terminated
      }
      lastTask = yield fork(saga, ...args.concat(action))
    }
})

function* cancelFetching() {
    yield cancel(lastTask);
}

const limit = 10;
var page = 0;

function* setPage(p) {
    page = p;
    yield call(cancelFetching);
}

function* fetchCouponHistory({couponId, groupId}) {
    page++;
    const history = yield call(GetResource, `/DepositGroup/${groupId}/coupon/${couponId}/history?page=${page}&limit=${limit}`);
    yield put({type: UPDATE_HISTORY_PAGINATION, pagination: {
            total: history.totalCount,
            page,
            limit
        }
    });
    history.data = history.data.map(historyItem => {
        return {
            ...historyItem,
            event: historyItem.event && JSON.parse(historyItem.event) || ''
        }
    });
    yield put({ type: SET_COUPONS_HISTORY, history: history.data });
}

function* couponHistorySaga() {
    yield takeLatest(REQUEST_COUPONS_HISTORY, safe(onError, fetchCouponHistory));
    yield takeLatest(RESET_COUPONS_HISTORY, safe(onError, setPage, 0));
}

export default couponHistorySaga;