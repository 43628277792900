import initialStore from "../initialStore";
import {
    REQUEST_DEPOSITS,
    SET_DEPOSITS,
    CLEAR_DEPOSITS,
} from "@actions/actionTypes";

export default function depositsReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_DEPOSITS:
            return  action.deposits;
        case CLEAR_DEPOSITS:
            return 
        default:
            return state;
    }
}