import Settings from "../pages/contents/settings";
import DepositGroups from "../pages/contents/depositGroups";
import DepositGroupsDetals from "../pages/contents/depositGroupsDetals";
import Wallets from "../pages/contents/wallets";
// import Deposit from "../pages/contents/deposit";
import Clients from "../pages/contents/clients";
import Transactions from "../pages/contents/transactions";

export default [
    {
        path: "/deposit_groups",
        component: DepositGroups
    },
    {
        path:'/deposit_groups_detals/:id/:clientId',
        component: DepositGroupsDetals
    },
    {
        path: "/wallets",
        component: Wallets
    },
    // {
    //     path: "/deposit",
    //     component: Deposit
    // },
    {
        path: "/clients",
        component: Clients
    },
    {
        path: "/settings",
        component: Settings
    },
    {
        path: "/transactions",
        component: Transactions
    }
]