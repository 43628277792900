import {
    REQUEST_PRODUCTS,
    CLEAR_PRODUCTS
} from "@actions/actionTypes";

export function getProducts(clientId) {
    return dispatch => {
        dispatch({
            type: REQUEST_PRODUCTS,
            clientId
        });
    }
}

export function clearProducts() {
    return dispatch => {
        dispatch({
            type: CLEAR_PRODUCTS
        });
    }
}