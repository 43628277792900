import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Form, Input, Select, DatePicker, Skeleton, Space} from "antd";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    getSingleCoupon
} from "@actions/couponsAction";
import {
    CouponGroupsValueArr,
    CouponGroupsNameArr
} from "../../constants/Enums";
import moment from "moment-timezone";
import {
    getDiffByDayMinuteSecond,
    getTimeLables,
    getByMinute,
    formatedStringByMinute,
    ordinal,
    getOrdinalSuffix
} from "../../utils/CommonUtils";

function CouponForm({intl, editId, groupId, products, coupon, onCancel, loading, onChange, getSingleCoupon}) {

    const [form] = Form.useForm();
    const [isSubmitActive, setIsSubmitActive] = useState(!editId);

    const getExpiredDate = (date) => {
        let diff = getTimeLables(getDiffByDayMinuteSecond(new Date(date).getTime() - new Date().getTime()), intl);
        return diff;
    }

    const onFinish = (data) => {
        if (editId) {
            data.id = editId;
        }

        data.minAmount = +data.minAmount;
        data.maxAmount = +data.maxAmount;
        data.minBalance = +data.minBalance;
        data.expiresIn = getByMinute(data.expiresIn);
        data.percentage = data.percentage.replace(new RegExp('%', 'g'), '');
        data.wagering = data.wagering.replace(new RegExp('x', 'g'), '');
        data.on && (data.on = parseInt(data.on));
        onChange(data);
    }

    useEffect(() => {
        if (editId) {
            getSingleCoupon(editId, groupId);
        }
    }, [editId])

    useEffect(() => {
        if (editId) {
            form.setFieldsValue({
                ...coupon,
                expiresIn: formatedStringByMinute(coupon.expiresIn),
                percentage: coupon.percentage + '%',
                wagering: coupon.wagering + 'x',
                on: coupon.on && (coupon.on + getOrdinalSuffix(coupon.on)) || '',
                productsId: coupon?.products?.map(product => product.id)
            });
        }
    }, [coupon])

    const removeAddedSimbol = (e, simbol, element) => {
        if (form.getFieldValue(element)) {
            form.setFieldsValue({[element]: (form.getFieldValue(element)).toString().replace(new RegExp(simbol, 'g'), '')});
        }
    }

    const addSimbol = (e, simbol, element) => {
        const val = form.getFieldValue(element);
        if (val && (!isNaN(val) || !isNaN((val).toString().replace(new RegExp(simbol, 'g'), '')))) {
            form.setFieldsValue({[element]: (val).toString().replace(new RegExp(simbol, 'g'), '')});
            form.setFieldsValue({[element]: form.getFieldValue(element) + simbol});
            form.validateFields([element])
        }
    }

    const changeValue = (a, b, c) => {
        if (!editId) return;

        let isChanged = false;
        const oldCoupon = {
            ...coupon,
            expiresIn: coupon.expiresIn,
            percentage: coupon.percentage + '%',
            wagering: coupon.wagering + 'x',
            on: coupon.on && (coupon.on + getOrdinalSuffix(coupon.on)) || ''
        }
        
        b.expiresIn =  getByMinute(b.expiresIn);

        for (let key in b) {
            if (b[key] instanceof moment) {
                if (b[key].diff(oldCoupon[key]) != 0) {
                    isChanged = true;
                }
            } else if (b[key] != oldCoupon[key]) {
                isChanged = true;
            }
        }

        setIsSubmitActive(isChanged);
    }

    return  (
        <div className="coupon-form-block">
            <div className="coupon-form-header">
                <div className="coupon-form-title"><FormattedMessage id={(editId? 'edit' : 'add') + '_coupon'}/></div>
            </div>

            {(!editId || (editId && !loading['_single_coupon' + editId])) &&
            <Form
                layout="vertical"
                form={form}
                name="coupon_form"
                className="coupon_form"
                onValuesChange={changeValue}
                onFinish={onFinish}>
                <Form.Item
                    name="name"
                    label={<FormattedMessage id='coupon_name'/>}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id='coupon_name_validator'/>
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (value && (value.length < 3 || value.length > 50)) {
                                    return Promise.reject(intl.formatMessage({id: `coupon_name_length_is_not_valid`}));
                                }
                                return Promise.resolve();
                            },
                        })
                    ]}
                >
                    <Input placeholder={intl.formatMessage({id: `type_coupon_name`})}/>
                </Form.Item>
                <Form.Item
                    name="status"
                    hidden={true}
                    label={<FormattedMessage id='status'/>}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="percentage"
                    label={<FormattedMessage id='percentage'/>}
                    initialValue={'1%'}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id='coupon_percentage_validator'/>
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                const numberRegExp = new RegExp(/^\d+(\.\d{1,2})?\%?$/);
                                if (value && !numberRegExp.test(value) || parseFloat(value) === 0 || parseFloat(value) > 100) {
                                    return Promise.reject(intl.formatMessage({id: `percentage_is_not_valid`}));
                                }
                                return Promise.resolve();
                            },
                        })
                    ]}
                >
                    <Input placeholder={intl.formatMessage({id: `type_percentage`})}
                            onFocus={(e) => removeAddedSimbol(e, '%', 'percentage')}
                            onBlur={(e) => addSimbol(e, '%', 'percentage')}
                            spellCheck="false"/>
                </Form.Item>

                <Form.Item
                    name="wagering"
                    label={<FormattedMessage id='wagering'/>}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id='group_wagering_validator'/>
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                const numberRegExp = new RegExp( /^\d+(\.\d{1,2})?\x?$/);
                                if (value && !numberRegExp.test(value)) {
                                    return Promise.reject(intl.formatMessage({id: `wagering_is_not_valid`}));
                                }
                                if (parseFloat(value) === 0) {
                                    return Promise.reject(intl.formatMessage({id: `wagering_is_zero`}));
                                }
                                return Promise.resolve();
                            },
                        })
                    ]}
                >
                    <Input placeholder={intl.formatMessage({id: `type_wagering`})}
                            onFocus={(e) => removeAddedSimbol(e, 'x', 'wagering')}
                            onBlur={(e) => addSimbol(e, 'x', 'wagering')}
                            spellCheck="false"/>
                </Form.Item>

                <Form.Item
                    name="expiresIn"
                    label={<FormattedMessage id='expires_in'/>}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id='coupon_expires_in_validator'/>
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (value) {
                                    const numberRegExp = new RegExp(/^\d+(\d{1,2})?(y|m|w|d)$/);
                                    const values = value.split(' ').filter(item => item);
                                    if (!values.length) {
                                        return Promise.reject(<><span>{intl.formatMessage({id: `coupon_expires_in_is_not_valid1`})}</span><span>{intl.formatMessage({id: `coupon_expires_in_is_not_valid2`})}</span></>);
                                    }
                                    for(let i = 0; i < values.length; ++i) {
                                        if (!numberRegExp.test(values[i]) || !parseInt(values[i])) {
                                            return Promise.reject(<><span>{intl.formatMessage({id: `coupon_expires_in_is_not_valid1`})}</span><span>{intl.formatMessage({id: `coupon_expires_in_is_not_valid2`})}</span></>);
                                        }
                                    }
                                }
                                return Promise.resolve();
                            },
                        })
                    ]}
                >
                    <Input placeholder={intl.formatMessage({id: 'placeholder_expires_in'})} autoComplete="off" spellCheck="false"/>
                </Form.Item>

                <Form.Item
                    name="maxAmount"
                    label={<FormattedMessage id='max_amount'/>}
                    rules={[
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (value) {
                                    const numberRegExp = new RegExp(/^\d+(\.\d{1,6})?$/);
                                    if (value < 0 || !numberRegExp.test(value)) {
                                        return Promise.reject(intl.formatMessage({id: `max_amount_is_not_valid`}));
                                    }
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <Input placeholder={intl.formatMessage({id: `type_amount`})}/>
                </Form.Item>

                <Form.Item
                    name="minAmount"
                    label={<FormattedMessage id='min_amount'/>}
                    rules={[
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (value) {
                                    const numberRegExp = new RegExp(/^\d+(\.\d{1,6})?$/);
                                    if (value < 0 || !numberRegExp.test(value)) {
                                        return Promise.reject(intl.formatMessage({id: `min_amount_is_not_valid`}));
                                    }
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <Input placeholder={intl.formatMessage({id: `type_amount`})}/>
                </Form.Item>
                
                <Form.Item
                    name="on"
                    label={<FormattedMessage id='on'/>}
                    rules={[
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                const suffix = getOrdinalSuffix(value || 0);
                                const numberRegExp = new RegExp('^\\d+(\\d{1})?(' + suffix + ')?$');
                                if (value && !numberRegExp.test(value) || parseInt(value) === 0) {
                                    return Promise.reject(intl.formatMessage({id: `on_is_not_valid`}));
                                }
                                return Promise.resolve();
                            },
                        })
                    ]}
                >
                    <Input placeholder={intl.formatMessage({id: `on_deposit`})}
                            suffix={intl.formatMessage({id: `deposit`})}
                            onFocus={(e) => removeAddedSimbol(e, getOrdinalSuffix(e.target.value || 0), 'on')}
                            onBlur={(e) => addSimbol(e, getOrdinalSuffix(e.target.value || 0), 'on')} />
                </Form.Item>

                <Form.Item
                    name="minBalance"
                    label={<FormattedMessage id='min_balance'/>}
                    rules={[
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (value) {
                                    const numberRegExp = new RegExp(/^\d+(\.\d{1,2})?$/);
                                    if (value < 0 || !numberRegExp.test(value)) {
                                        return Promise.reject(intl.formatMessage({id: `min_balance_is_not_valid`}));
                                    }
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <Input placeholder={intl.formatMessage({id: `type_min_balance`})}/>
                </Form.Item>

                <Form.Item
                    name="productsId"
                    label={<FormattedMessage id='products'/>}
                >
                    <Select allowClear
                        mode="multiple"
                        className="product-list-select"
                        placeholder={intl.formatMessage({id: `select_products`})}
                        getPopupContainer={triggerNode => triggerNode.parentElement}>
                        {products.map(product => (
                            <Select.Option key={product.id}
                                value={product.id} >
                                {product.name}
                            </Select.Option>
                        ))}
                        
                    </Select>
                </Form.Item>

                <Form.Item className="MainModal--footer">
                    <button type="reset" className="miniBtn default border" onClick={onCancel}>
                        <FormattedMessage id='cancel'/>
                    </button>
                    <button type="submit" className="miniBtn" disabled={!isSubmitActive}>
                        <FormattedMessage id={editId? 'save' : 'add'}/>
                    </button>
                </Form.Item>
            </Form> || 
            <>

                    <Skeleton.Input className="lable" active size='small' />
                    <Skeleton.Input active size='small' />
                    <Skeleton.Input className="lable" active size='small' />
                    <Skeleton.Input active size='small' />
                    <Skeleton.Input className="lable" active size='small' />
                    <Skeleton.Input active size='small' />
                    <Skeleton.Input className="lable" active size='small' />
                    <Skeleton.Input active size='small' />
                    <Skeleton.Input className="lable" active size='small' />
                    <Skeleton.Input active size='small' />
                    <Skeleton.Input className="lable" active size='small' />
                    <Skeleton.Input active size='small' />
                    <Skeleton.Input className="lable" active size='small' />
                    <Skeleton.Input active size='small' />
                    <Skeleton.Input className="lable" active size='small' />
                    <Skeleton.Input active size='small' />
                    <Space className="button-skeleton">
                        <Skeleton.Button width={111} active size='small' />
                        <Skeleton.Button width={111} active size='small' />
                    </Space>

            </>
            }
        </div>
    )
}
function mapStateToProps(state) {
    return {
       products: state.products,
       depositGroups: state.depositGroups,
       coupon: state.coupon,
       loading: state.loading
    }
}

const mapDispatchToProps = {
    getSingleCoupon
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CouponForm));