import initialStore from "../initialStore";
import {
    SET_PRODUCTS,
    CLEAR_PRODUCTS,
} from "@actions/actionTypes";

export default function productsReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_PRODUCTS:
            return  action.products || initialStore.emptyArr
        case CLEAR_PRODUCTS:
            return initialStore.emptyArr
        default:
            return state;
    }
}


