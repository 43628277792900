import React, { useEffect, useRef, useState } from "react";
import {connect} from "react-redux";
import {Switch, Skeleton, Input} from "antd";
import {FormattedMessage} from "react-intl";
import ImgUpload from "@components/ImgUpload/ImgUpload";
import {
    toggleGroupStatus,
    getGroup,
    editGroup
} from "@actions/depositGroupsAction";

const { TextArea } = Input;

function GroupItem({ loading, selectedGroup, clientId, setIsShowCouponForm, isShowCouponForm, toggleGroupStatus, editGroup}) {

    const [descriptionEditMode, setDescriptionEditMode] = useState(false);
    const [nameEditMode, setNameEditMode] = useState(false);

    const saveGroupImage = (url) => {
        editGroup({
            ...selectedGroup,
            icon: url
        }, clientId);
    }

    const changeDescription = (e) => {
        if (e.target.value != selectedGroup.description) {
            editGroup({
                ...selectedGroup,
                description: e.target.value
            }, clientId);
        }
        
        setDescriptionEditMode(false);
    }

    const changeName = (e) => {
        if (e.target.value != selectedGroup.name) {
            editGroup({
                ...selectedGroup,
                name: e.target.value
            }, clientId);
        }
        
        setNameEditMode(false);
    }

    const activateDescriptionEditMode = () => {
        setDescriptionEditMode(true);
    }
    const activateNameEditMode = () => {
        setNameEditMode(true);
    }

    useEffect(() => {
        if (descriptionEditMode) {
            requestAnimationFrame(() => {
                const textarea = document.querySelector('.group-item textarea');
                // textarea.selectionEnd = toString(textarea.innerHTML).length;
                textarea.focus();
            })
        }
    }, [descriptionEditMode])

    useEffect(() => {
        if (nameEditMode) {
            document.querySelector('.detals input.name-input ').focus();
        }
    }, [nameEditMode])

    return (
        <div className="group-item">
            {loading._deposit_group != undefined && !loading._deposit_group && <>
                <ImgUpload onChange={saveGroupImage} value={selectedGroup.icon}/>
                <div className="detals">
                    {!nameEditMode && (loading['_edit_deposit_group' + selectedGroup.id] ?
                        <Skeleton.Input active="active" style={{height: 21}}/> :
                        <h3 onDoubleClick={activateNameEditMode}>{selectedGroup.name}</h3>) ||
                        <Input className="name-input" style={{height: 30}} onBlur={changeName} defaultValue={selectedGroup.name}/>}
                    {!descriptionEditMode &&
                        (loading['_edit_deposit_group' + selectedGroup.id] ? <Skeleton.Input active="active" style={{height: 45}}/> :
                        <p onDoubleClick={activateDescriptionEditMode}>{selectedGroup.description}</p>) ||
                        <TextArea rows={2}
                                    // autoFocus={true}
                                    style={{height: 50}}
                                    onBlur={changeDescription}
                                    defaultValue={selectedGroup.description}/>}
                    <hr/>
                    <div className="enb-dis-block">
                        <span><FormattedMessage id="status"/>: </span>
                        <Switch className="radioSwitch" checked={selectedGroup.status} loading={loading['_on_off_group' + selectedGroup.id]} onChange={() => toggleGroupStatus(selectedGroup, clientId)}/>
                    </div>
                    <button className="miniBtn"
                            disabled={isShowCouponForm}
                            onClick={() => setIsShowCouponForm(true)}>
                                <FormattedMessage id="add_coupon"/>
                    </button>

                </div>
            </> || 
            <>
                <div>
                    <Skeleton.Image style={{width:150, height: 150}}/>
                </div>
                
                <div className="detals">
                    <Skeleton active="active" paragraph={{ rows: 2 }} />
                    <Skeleton.Button active="active" size="small" shape="round" />
                </div>
            </>}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        selectedGroup: state.selectedGroup,
        loading: state.loading
    }
}

const mapDispatchToProps = {
    toggleGroupStatus,
    editGroup,
    getGroup
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupItem);