import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getFormattedUrlObject, getUrlString, getUrlValue} from "../../../utils/CommonUtils";
import {getWallets, clearWallets} from "@actions/walletsAction";
import classNames from "classnames";
import {
    WalletsFilter,
    WalletsTable
} from "@components/Wallets";
import TableLoader from "@components/Loaders/tableLoader";
import filterIcon from "@assets/img/icon/filter.svg";
import {
    setPagination
} from "@actions/paginationsActions";

function Wallets({loading, pagination, user, setPagination, getWallets, clearWallets}) {
    let history = useHistory()
    const search = history && history.location && history.location.search
    const [showFilter, setShowFilter] = useState(false);

    const getWalletsRequestBody = (loc) => {
        const urlObj = getUrlValue( loc || search);
        let initialForm = getFormattedUrlObject(urlObj)
        return {
            ...initialForm,
            clientId: initialForm.clientId ? initialForm.clientId : "",
            playerName: initialForm.playerName ? initialForm.playerName : "",
            productId: initialForm.productId ? initialForm.productId : "",
            statuses: initialForm.statuses ? initialForm.statuses.toString() : ""
        }
    }

    const fetchWalets = (isFirst) => {
        let initialForm = getWalletsRequestBody()
        initialForm = {
            ...initialForm,
            page : Math.max(1, (isFirst ? (initialForm.page || 1) : (initialForm.page || pagination.page))),
            limit: Math.max(10, isFirst ? (initialForm.limit || 10) : (initialForm.limit || pagination.limit))
        }
        if(Object.keys(initialForm).length) {
            getWallets({...initialForm});
            const {params} = getUrlString(initialForm)
            isFirst ? history.replace({search: params.toString()}) : history.push({search: params.toString()})
        }
    }

    useEffect(() => {
        const urlObj = getUrlValue(search);
        setPagination({
            page: (Math.max(1, +urlObj.page) || 1),
            limit: (Math.max(10, +urlObj.limit) || 10)
        });
        setTimeout(() => {
            fetchWalets(true);
        }, 10)
        return () => {
            setPagination({
                page: 1,
                limit: 10
            });
            clearWallets()
        }
    }, [])

    useEffect(() => {
        return history.listen((location) => {
            const loc = location.search;
            if (loc) {
                const initialForm = getWalletsRequestBody(loc);
                setPagination({
                    page: Math.max(1, (+initialForm.page || pagination.page)),
                    limit: (+initialForm.limit || pagination.limit)
                });
                if (!(user?.profile?.IsAdmin || user?.profile?.IsSuperAdmin)) {
                    initialForm.clientId = user?.profile.sub;
                }
                getWallets({...initialForm, page: Math.max(1, (+initialForm.page || pagination.page))});
            } else {
                setPagination({
                    page: 1,
                    limit: 10
                });
            }
        });
    },[history])


    const handleTablePaginate = (changedPage, changedLimit) => {
        let newPage = 1;
        pagination.limit == changedLimit && (newPage = changedPage);
        setPagination({
            page: newPage,
            limit: changedLimit
        });
        const initialForm = getWalletsRequestBody()

        const {params} = getUrlString({
            ...initialForm,
            page: newPage,
            limit: changedLimit
        })
        history.push({search: params.toString()});
    }
    
    return (
        <div className="content wallets">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='wallets'/></div>
                {/* <button className={classNames("filterBtn", {show: showFilter})} onClick={() => setShowFilter(prev => !prev)}>
                   <div className="icon"/>
                    <FormattedMessage id='filter'/>
                </button> */}
            </div>
            <WalletsFilter showFilter={showFilter}/>
            <div className="wallets">
                <div className="mainTable">
                    {loading._wallets === undefined || loading._wallets ? <TableLoader count={10} column={9} actions={0} drag={false} icon={false}/> :
                        <div>
                            <WalletsTable changePagination={handleTablePaginate}/>
                        </div>
                    }
                </div>

            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        wallets: state.wallets,
        loading: state.loading,
        pagination: state.pagination,
        user: state.user
    }
}

const mapDispatchToProps = {
    getWallets,
    clearWallets,
    setPagination
}

export default connect(mapStateToProps, mapDispatchToProps)(Wallets);