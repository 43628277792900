import {call, put, delay, take, cancel, fork} from 'redux-saga/effects';
import {
    REQUEST_DEPOSITS,
    SET_DEPOSITS,
    UPDATE_PAGINATION,
    UPDATE_BY_START_PAGINATION,
    UPDATE_PAGINATION_TOTAL,
    SET_DEPOSITS_CSV_DOWNLOADER_URL,
    REQUEST_DEPOSITS_CSV_DOWNLOADER_URL,
    SET_CANCEL_DEPOSIT_CSV_DOWNLOAD,
    REQUEST_CANCEL_DEPOSIT_CSV_DOWNLOAD,
    SET_ALLOW_CSV_CANCEL
} from "@actions/actionTypes";
import {
    GetResource,
    DeleteResource,
    PutResource,
    PostResource
} from '../../utils/HttpUtil';
import {onError, safe} from "./handlerError";
import initialStore from "../initialStore";
import {getQuery} from "../../utils/CommonUtils";
import {waitForFile} from "@services/CsvDownloadService";
import {getFormattedBody} from "@services/TableServices";
import "./csvProcess";
const API_URL = process.env.BONUS_SYSTEM_DEPOSIT_API_PATH;
let pagination = initialStore.pagination;
let oldFilter = {};
let downloadTask

const takeLatest = (patternOrChannel, saga, ...args) => fork(function*() {
    while (true) {
        const action = yield take(patternOrChannel)
        if (patternOrChannel === REQUEST_DEPOSITS_CSV_DOWNLOADER_URL) {
            downloadTask = yield fork(saga, ...args.concat(action))
        } else {
            yield fork(saga, ...args.concat(action))
        }

    }
})

const getCorectFilter = (filter) => {
    if (filter && Object.keys(filter).length > 0) {
        oldFilter = filter;
    }
    return oldFilter;
}

let lang = 1;

function* setPagination(action) {
    pagination = action.pagination;
}

function* resetPagination(action) {
    pagination = action.pagination.Limit && action.pagination.Page && action.pagination || initialStore.pagination;
}


function* fetchDeposits({params}) {
    let query = getQuery(params);
    const deposits = yield call(GetResource, `/Deposit?${query}`, API_URL);
    yield put({type: UPDATE_PAGINATION_TOTAL, total: deposits.totalCount});
    yield put({ type: SET_DEPOSITS, deposits: deposits.data? deposits.data : []});
}

function* fetchDepositsCsvDownloaderUrl({data, ...restProps}) {
    const checkProcessUrl =  "/Deposit/getreporturl";
    let id = restProps.processId;
    if (!id) {
        const query = getQuery(data)
        id = yield call(GetResource, `/Deposit/generatereport?${query}`, API_URL);
    }
    window.CsvProcess.setProcess({
        actionType: REQUEST_DEPOSITS_CSV_DOWNLOADER_URL,
        processId: id,
        checkProcessUrl
    })
    yield put({type: SET_ALLOW_CSV_CANCEL, allow: {allowDeposits: true}})
    const link = yield waitForFile(REQUEST_DEPOSITS_CSV_DOWNLOADER_URL, id, checkProcessUrl, API_URL);
    yield put({type: SET_DEPOSITS_CSV_DOWNLOADER_URL, link})
    yield put({type: SET_ALLOW_CSV_CANCEL, allow: {allowDeposits: false}})
    window.CsvProcess.removeProcess(id);

}

function* cancelDepositCsvDownload(processId) {
    window.CsvProcess.setCanceled(processId);
    if (processId) {
        yield call(PutResource, `/Deposit/cancelreport?key=${processId}`, {}, API_URL)
    }
    yield delay(2000)
    yield put({type: SET_ALLOW_CSV_CANCEL, allow: {allowDeposits: false}})
    yield put({type: SET_DEPOSITS_CSV_DOWNLOADER_URL, link: ""});
    yield put({type: SET_CANCEL_DEPOSIT_CSV_DOWNLOAD})
    window.CsvProcess.removeProcess(processId);
}

function* cancelFetching() {
    yield cancel(downloadTask);
    const processId = window.CsvProcess.getProcessIdByAction(REQUEST_DEPOSITS_CSV_DOWNLOADER_URL)
    yield cancelDepositCsvDownload(processId)
}

function* depositsSaga() {
    yield takeLatest(REQUEST_DEPOSITS, safe(onError, fetchDeposits));
    yield takeLatest(UPDATE_PAGINATION, safe(onError, setPagination));
    yield takeLatest(UPDATE_BY_START_PAGINATION, safe(onError, resetPagination));
    yield takeLatest(REQUEST_DEPOSITS_CSV_DOWNLOADER_URL, safe(onError, fetchDepositsCsvDownloaderUrl));
    yield takeLatest(REQUEST_CANCEL_DEPOSIT_CSV_DOWNLOAD, safe(onError, cancelFetching))
}
 
export default depositsSaga;