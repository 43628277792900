import moment from "moment";


export const getDateForRequest = (date, formatted =false) => {
    if (date && date[0] && date[1]) {
        return {
            start: formatted ? moment(date[0]).utc().format() : moment(date[0].toString()),
            end: formatted ? moment(date[1]).utc().format() : moment(date[1].toString())
        }
    }
    return {start: "", end: ""}
}

export const getFormattedForForm = (data) => {
    const initialData = {}
    Object.keys(data).forEach(key => {
        if (key === "types" || key === "statuses" || key === "assets") {
            if(!Array.isArray(data[key])) {
                initialData[key] = data[key].split(",")
            } else {
                initialData[key] = data[key]
            }
        } else {
            initialData[key] = data[key]
        }
    })
    return initialData
}

export const copyLabel = (e, copyLabel) => {
    return new Promise((resolve, reject) => {
        try {
            navigator.clipboard.writeText(copyLabel)
                .then(() => {
                    resolve()
                })
                .catch(err => {
                    reject()
                });
        } catch(er) {
            try {
                const inp = document.createElement('input');
                inp.value = e.currentTarget.innerText;
                inp.style.position = 'absolute';
                inp.style.zIndex = "-1";
                document.documentElement.appendChild(inp);
                setTimeout(() => {
                    try {
                        inp.select();
                        document.execCommand("copy");
                        resolve()
                    } catch (e) {
                        reject()
                    }
                }, 10)
            } catch (e) {
                reject()
            }
        }
    })
}


export const getFormattedBody = (data) => {
    const body = {}
    Object.keys(data).forEach(item => {
        if (data[item]) {
            if (typeof data[item] === "object") {
                if (data[item][0] instanceof moment) {

                    body[item+"Start"] = moment(data[item][0]).utc().format()
                    body[item+"End"] = moment(data[item][1]).utc().format()
                } else {
                    body[item] = data[item] ? data[item].toString() : ""
                }
            } else {
                body[item] = data[item]
            }
        }
    })
    return body
}