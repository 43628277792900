import initialStore from "../initialStore";
import {
    SET_WALLETS,
    CLEAR_WALLETS
} from "@actions/actionTypes";

export default function walletsReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_WALLETS:
            return action.wallets;
        case CLEAR_WALLETS:
            return initialStore.emptyArr;
        default:
            return state;
    }
}
