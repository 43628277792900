import React, {useEffect, useContext, useState} from "react";
import {connect} from "react-redux";
import {
    addClient,
    editClient
} from "@actions/clientsAction";
import {
    getUsers
} from "@actions/usersAction";
import {
    REQUEST_ADD_CLIENT,
    REQUEST_EDIT_CLIENT
} from "@actions/actionTypes";

import {Modal, Form, Input, Row, Radio, Switch, Tabs, Space, Button} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import classNames from "classnames";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import UsersSelect from "@components/UsersSelect";


function ClientControlPopup({handleCancel, show, intl, users, addClient, client, loading, editClient, getUsers}) {
    const [form] = Form.useForm();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    const [tabMode, setTabMode] = useState("1");
    const [firstTabError, setFirstTabError] = useState(false)
    const [secondTabValid, setSecondTabValid] = useState(false)
    const [isFirstOpen, setIsFirstOpen] = useState(!client?.id)

    useEffect(() => {
        if(client.id) {
            form.setFieldsValue(client)
        }
    }, [client])

    const handleModeChange = (e) => {
        const mode = e.target.value;
        if (mode === "2") {
            setIsFirstOpen(false)
        }
        setTabMode(mode);
    }

    useEffect( () => {
        if (isFirstOpen) {
            return
        }
        formValueChange();
    }, [tabMode])

    const formValueChange = () => {
        let firstTabValid = false;
        let secondTabValid = false;
        setFirstTabError(false);
        setSecondTabValid(false);

        form.getFieldsError().some((field) => {
            if (field.name.length === 3) {
                secondTabValid = secondTabValid || field.errors.length > 0
            } else {
                firstTabValid = firstTabValid || field.errors.length > 0
            }
            
        })
        setFirstTabError(firstTabValid)
        setSecondTabValid(secondTabValid)
    }


    useEffect(() => {
        if (client.id) {
            subscribeForError(REQUEST_EDIT_CLIENT, () => {
                handleCancel();
            }, client.id);
        } else {
            subscribeForError(REQUEST_ADD_CLIENT, () => {
                handleCancel();
            });
        }
        getUsers();
        return () => {
            unSubscribeForError(REQUEST_ADD_CLIENT);
            unSubscribeForError(REQUEST_EDIT_CLIENT);
        }
    }, [])

    const onFinish = (data) => {
        if (!data.products) {
            data.products = show === "add"? [] : client.products
        }
        if (show === "add") {
            const selectedUser = users.find(user => user.id == data.id);
            addClient({...data, name: `${selectedUser.firstName} ${selectedUser.lastName}`});
        } else {
            editClient({...data, id: client.id, name: client.name});
        }
    }

    return (
        <>
            {

                <Modal
                    title={show === "add" ? <FormattedMessage id='add_client'/> :
                        <FormattedMessage id='edit_client'/>}
                    centered
                    className={`formModal`}
                    visible={show}
                    width={596}
                    onCancel={() => handleCancel()}
                    footer={false}>
                        <div className="tabMode centeredBox">
                            <Radio.Group onChange={handleModeChange} value={tabMode}>
                                <Radio.Button className={classNames("centeredBox", {invalid: firstTabError})} value="1"><FormattedMessage id={'details'}/></Radio.Button>
                                <Radio.Button className={classNames("centeredBox", {invalid: secondTabValid})} value="2"><FormattedMessage
                                    id={'products'}/></Radio.Button>
                            </Radio.Group>
                        </div>
                    <Form
                        layout="vertical"
                        form={form}
                        name="client-form"
                        onFinish={onFinish}
                        onFieldsChange={formValueChange}
                        scrollToFirstError
                        validateTrigger="onBlur"
                    >
                        <Tabs activeKey={tabMode}>
                            <Tabs.TabPane key="1">
                                {show === "edit" ?
                                    <>
                                        <Form.Item label={<FormattedMessage id='id'/>}>
                                            {client.id}
                                        </Form.Item>
                                        <Form.Item label={<FormattedMessage id='name'/>}>
                                            {client.name}
                                        </Form.Item>
                                    </> :
                                    
                                    <Form.Item label={<FormattedMessage id='user'/>}
                                                rules={[
                                                    {
                                                        required: show === "add",
                                                        message: <FormattedMessage id='user_validator'/>
                                                    }
                                                ]}
                                                name={"id"}>
                                        <UsersSelect />
                                    </Form.Item>
                                }
                                <Form.Item label={<FormattedMessage id='secret'/>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: <FormattedMessage id='secret_validator'/>
                                                },
                                                ({getFieldValue}) => ({
                                                    validator(_, value) {

                                                        if (value && (value.length > 256 || value.length < 3))
                                                        {
                                                            return Promise.reject(intl.formatMessage({id: `client_secret_valid`}))
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                })
                                            ]}
                                            name={"secret"}
                                >
                                    <Input autoComplete="off" spellCheck="false" placeholder={intl.formatMessage({id: "type_secret"})}/>
                                </Form.Item>
                                <Form.Item label={<FormattedMessage id='wallet_notification_url'/>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: <FormattedMessage id='wallet_notification_url_validator'/>
                                                },
                                                ({getFieldValue}) => ({
                                                    validator(_, value) {
                                                        const urlPattern = new RegExp( /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                        if (value && !urlPattern.test(value) ) {
                                                            return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                })
                                            ]}
                                            name={"walletNotificationUrl"}
                                >
                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_wallet_notification_url"})}/>
                                </Form.Item>
                                <Form.Item
                                    label={<FormattedMessage id='select_status'/>}
                                    className=""
                                    name="isActive"
                                    valuePropName="checked">
                                    <Switch className="radioSwitch" />
                                </Form.Item>
                            </Tabs.TabPane>
                            <Tabs.TabPane key="2" className="products-tab">
                                <Space className="labels" style={{ display: 'flex', marginBottom: 8, width: '100%' }} align="baseline">
                                    <span><FormattedMessage id='name'/></span>
                                    <span><FormattedMessage id='key'/></span>
                                </Space>
                                <Form.List name="products">
                                    
                                    {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <Space className="inputs" key={key} style={{ display: 'flex', width: '100%'  }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                fieldKey={[fieldKey, 'name']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({id: "missing_name"})
                                                    },
                                                    ({getFieldValue}) => ({
                                                        validator(_, value) {
                                                           if (value && (value.length < 3 || value.length > 256)) {
                                                                return Promise.reject(intl.formatMessage({id: `client_name_not_valid`}));
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    })
                                                ]}
                                                >
                                                <Input placeholder={intl.formatMessage({id: "type_name"})} />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'key']}
                                                fieldKey={[fieldKey, 'key']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({id: "missing_key"})
                                                    },
                                                    ({getFieldValue}) => ({
                                                        validator(_, value) {
                                                           if (value && (value.length < 3 || value.length > 256)) {
                                                                return Promise.reject(intl.formatMessage({id: `key_is_not_valid`}));
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    })
                                                ]}
                                                >
                                                <Input placeholder={intl.formatMessage({id: "type_key"})} />
                                            </Form.Item>
                                            <div className="delete-icon icon" onClick={() => {remove(name); setTimeout(() => formValueChange(), 10)} }/>
                                        </Space>
                                        ))}
                                        <Form.Item className="add-button">
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                <FormattedMessage id='add'/>
                                            </Button>
                                        </Form.Item>
                                    </>
                                    )}
                                </Form.List>
                            </Tabs.TabPane>
                        </Tabs>
                        <Form.Item className="MainModal--footer">
                            <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                <FormattedMessage id='cancel'/>
                            </button>
                            <Button type="primary"
                                    htmlType="submit"
                                    style={{height: 36}}
                                    className={classNames("miniBtn", {submit: firstTabError || secondTabValid })}
                                    loading={loading._add_client || loading['_edit_client' + client.id]}
                                    disabled={firstTabError || secondTabValid}
                            >
                                <FormattedMessage id='save'/>
                            </Button>
                            <div className="error_box"><FormattedMessage id={"form_error"}/></div>
                        </Form.Item>
                    </Form>
                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        users: state.users
    }
}

const mapDispatchToProps = {
    addClient,
    editClient,
    getUsers
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ClientControlPopup));