import {transform} from 'lodash';
import {encodeRequestBody} from './HttpUtil';
import {DefaultLanguage, LanguageForApi} from "@constants/Enums";
import moment from "moment-timezone";

const YearDivider = (1000 * 60 * 60 * 24 * 365);
const MountDivider = (1000 * 60 * 60 * 24 * 30);
const WeekDivider = (1000 * 60 * 60 * 24 * 7);
const HoursDivider = (1000 * 60 * 60 * 24);
const MinutesDivider = (1000 * 60 * 60);
const SecondsDivider = (1000 * 60);

const multipliers = {
    y: 525600,
    m: 44640,
    w: 10080,
    d: 1440
}

const multipliersKeys = Object.keys(multipliers);

export function transformKeysToLowerCase(data) {
    return transform(data, function (result, val, key) {
        result[key.toLowerCase()] = val;
    })
}

export function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}

export const Re8Characers = /(?!.*\s).{8,}$/;
export const ReLowerCase = /(?=(.*[a-z]){1,})/;
export const ReUpperCase = /^(?=.*?[A-Z])/;
export const ReNumber = /\d/;

export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export function correctedFilter(filter) {
    let keys = Object.keys(filter).filter(item => filter[item]);
    let obj = {};
    keys.forEach(k => {
        obj[k] = filter[k];
    });
    return obj;
}

export function grtParamsQueryByFilter(filter, pagination) {
    let filterParams = encodeRequestBody({
        ...filter,
        Lang: LanguageForApi[(localStorage.getItem('lang') || DefaultLanguage)]
    });
    let paginationParams = pagination ? '&' + encodeRequestBody({
        Page: pagination.Page,
        Limit: pagination.Limit
    }) : '';
    return `?${filterParams}${paginationParams}`;
}

export const addNameProperty = (obj) => {
    obj.name = obj[(localStorage.getItem('lang') || DefaultLanguage)];
    return obj
}

export function correctedBy(list) {
    return list.map((item, i) => {
        return {
            id: item.id,
            orderId: i + 1
        }
    });
}

export function addIndexWithDragDrop(data) {
    if (data && data.length > 0) {
        data.sort((a, b) => a.orderId - b.orderId).forEach((item, index) => {
            item.index = index + 1;
        });
        return data;
    }
    return [];
}

export function isAddOrEditData(type) {
    return filterActions(type, 'ADD') || filterActions(type, 'EDIT');
}

function filterActions(type, key) {
    let t;
    if (type.search(key) != -1) {
        t = type.replace(key, '').toLowerCase();
    }
    return t;
}

export function isRequest(type) {
    return filterActions(type, 'REQUEST');
}

export function isSetData(type) {
    return filterActions(type, 'SET');
}

export function isSetDataAndCsvUpload(type) {
    return (filterActions(type, 'SET') && filterActions(type, 'CSV'));
}

export const getUrlValue = (str) => {
    const urlParams = {}
    if (str) {
        const url = new URLSearchParams(str)
        for (const [key, value] of url) {
            if (key === "ip") {
                const ipRegExp = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g.test(value);
                if (!ipRegExp) continue;
            }
            urlParams[`${key}`] = value || ''
        }
    }
    return urlParams;

}

export const getUrlString = (urlParams) => {
    let params = new URLSearchParams();

    Object.keys(urlParams).forEach(item => {
        if (typeof urlParams[item] !== 'object') {
            if (urlParams[item] != undefined && urlParams[item] != "") {
                params.set(item, urlParams[item])
            }
        } else {
            if (urlParams[item].start) {
                params.set(""+item+"Start", urlParams[item].start)
            }
            if (urlParams[item].end) {
                params.set(""+item+"End", urlParams[item].end)
            }
        }
    });
    return {params}
}

export const getFormattedUrlObject = (urlObj) => {
    let initialObj = {};

    const urlObjKeys = Object.keys(urlObj)
    if (urlObjKeys.length) {
        urlObjKeys.forEach(urlParam => {
            if(urlParam.indexOf("Range") > -1) {
                const position = urlParam.indexOf("End") > -1 ? "end" : "start"
                const changedKey = urlParam.replace("Start","")
                    .replace("End","");
                (!initialObj[changedKey]) && (initialObj[changedKey] = {});
                initialObj[changedKey][position] = urlParam.indexOf("date") > -1 ? moment(urlObj[urlParam]) : urlObj[urlParam]
            } else {
                initialObj[urlParam] = urlObj[urlParam]
            }

        })
    }
    return initialObj
}

export function getQuery(params) {
    let query = '';
    Object.keys(params).forEach(item => {
        if (typeof params[item] !== 'object') {
            if(params[item] !== "") {
                if (item === "statuses" || item === "types" || item === "ProductKeys" || item === "ProductId") {
                    const select =  params[item].split(",");
                    select.forEach(s => query += item + '=' + s + '&');
                } else {
                    query += item + '=' +  encodeURIComponent(params[item]) + '&';
                }
            }
        } else {
            if (params[item].start) {
                query += item.replace('Range', '') + 'From=' + (params[item].start || "") + '&';
            }
            if( params[item].end) {
                query += item.replace('Range', '') + 'To=' + (params[item].end || "") + '&';
            }
        }
    })
    if (query) {
        query = query.slice(0, -1);
    }
    return query
}

export const downloadCVS = (query, src) => {
    window.open(`${process.env.BONUS_SYSTEM_API_PATH}${src}?${query}`, "_blank")
}

export function getTimeLables(diff, intl) {
    if (diff) {
        return `${diff.year ? diff.year + intl.formatMessage({id: "yearShort"}) + " " : ""}` +
        `${diff.mount ? diff.mount + intl.formatMessage({id: "mountShort"}) + " " : ""}` +
        `${diff.week ? diff.week + intl.formatMessage({id: "weekShort"}) + " " : ""}` +
        `${diff.day ? diff.day + intl.formatMessage({id: "dayShort"}) + " " : ""}` +
        `${diff.hour ? diff.hour + intl.formatMessage({id: "hourShort"}) + " " : ""}`; // +
        // `${diff.minutes ? diff.minutes + intl.formatMessage({id: "minuteShort"}) + " " : ""}` +
        // `${diff.seconds ? diff.seconds + intl.formatMessage({id: "secondShort"}) + " " : ""}`;
    }
    return null;
}

export function getDiffByDayMinuteSecond(distance) {
    let year = Math.floor(distance / YearDivider);
    distance = distance - year * YearDivider;
    let mount = Math.floor(distance / MountDivider);
    distance = distance - mount * MountDivider;
    let week = Math.floor(distance / WeekDivider);
    distance = distance - week * WeekDivider;
    let day = Math.floor(distance / HoursDivider);
    let hours = Math.floor((distance % HoursDivider) / MinutesDivider);
    let minutes = Math.floor((distance % MinutesDivider) / SecondsDivider);
    let seconds = Math.floor((distance % SecondsDivider) / 1000);
    return {
        year,
        mount,
        week,
        day,
        hours,
        minutes,
        seconds
    }
}

export function getByMinute(str) {
    const values = str.split(' ').filter(item => item);
    let minutes = 0;
    values.forEach(v => {
        const count = v.slice(0, -1);
        const multiplier = v.replace(count, '');
        minutes += count * multipliers[multiplier];
    })
    return minutes;
}

export function formatedStringByMinute(minute) {
    let str = '';
    multipliersKeys.forEach(multiplier => {
        const v = Math.floor(minute / multipliers[multiplier]);
        if (v) {
            str += v + multiplier + ' ';
            minute -= multipliers[multiplier] * v;
        }
    })
    return str;
}

export function ordinal(number) {
    const english_ordinal_rules = new Intl.PluralRules("en", {
        type: "ordinal"
    });
    const suffixes = {
        one: "st",
        two: "nd",
        few: "rd",
        other: "th"
    }
    const suffix = suffixes[english_ordinal_rules.select(number)];
    return (number + suffix);
}

export function getOrdinalSuffix(n) {
    if (n == "always") return '';
    n = parseInt(n);
    var s = ["th", "st", "nd", "rd"];
    var v = n % 100;
    return (s[( v - 20 ) % 10] || s[v] || s[0]);
}

export const copyLabel = (e, copyLabel, setDoneMsg, setErrorMsg) => {

    try {
        navigator.clipboard.writeText(copyLabel)
            .then(() => {
                setDoneMsg("text_is_copied")
            })
            .catch(err => {
                setErrorMsg("something_wrong_the_text_is_not_copied")
            });
    } catch(er) {
        try {
            const inp = document.createElement('input');
            inp.value = e.currentTarget.innerText;
            inp.style.position = 'absolute';
            inp.style.zIndex = -1;
            document.documentElement.appendChild(inp);
            setTimeout(() => {
                inp.select();
                document.execCommand("copy");
            }, 10)

            setDoneMsg("text_is_copied");
        } catch (e) {
            setErrorMsg("something_wrong_the_text_is_not_copied")
        }
    }

}

export const checkNumberValidation = (e) => {
    if (!(/^[+-]?\d+(\.\d+)?$/.test(e.target.value + '' + e.key) || e.key == '.' && e.target.value.indexOf('.') === -1 || e.key == 'Backspace' || e.key == 'Enter')) {
        e.preventDefault()
    }
}