import React, { useState } from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";

import { Select } from 'antd';
import { useEffect } from "react";
import {
    getUsers
} from "@actions/usersAction";

const { Option } = Select;

function UsersSelect({users, intl, getUsers, value, onChange, loading, ...anyProps}) {
    const [usersSortedByName, setUsersSortedByName] = useState([]);

    useEffect(() => {
        getUsers();
    }, [])

    useEffect(() => {
        setUsersSortedByName(users.slice().sort((a, b) => {
                let aName = a.firstName.toLowerCase();
                let bName = b.firstName.toLowerCase();
                if (aName > bName) {
                    return 1;
                } else if (aName < bName) {
                   return -1;
                } else {
                    return 0;
                }
            }))
    }, [users])

    
    return (
        <Select
            dropdownClassName="users-dropdown"
            showSearch
            placeholder={intl.formatMessage({id: "select_user"})}
            optionFilterProp="children"
            value={value}
            onChange={onChange}
            {...anyProps}
            allowClear
            loading={loading._users}
            filterOption={(input, option) => {
                    return option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            }
        >
            {
                usersSortedByName.map(user => (<Option key={user.id} value={user.id}>{user.firstName} {user.lastName}</Option>))
            }
        </Select>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.loading,
        users: state.users
    }
}

const mapDispatchToProps = {
    getUsers
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UsersSelect));